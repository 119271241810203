import { Typography } from '@mui/material';
import { border, borderColor, Box, darken, style } from '@mui/system';
import { get } from 'http';
import { pxToRem, pxTovW } from '../../../commonUtils/resizeUtils';
const getRandomStyle = (): {
  bgColor: string;
  textColor: string;
  borderColor: string;
} => {
  const index = Math.floor(Math.random() * ColorsArray.length);
  return ColorsArray[index];
};
export const ClassSectionPillComp = ({
  backgroundColor,
  textColor,
  borderColor,
  className,
  children,
}: any) => {
  const style = getRandomStyle();

  return (
    <Box
      sx={{
        display: 'inline-flex',
        boxSizing: 'border-box',
        height: { xs: pxToRem(32), md: pxTovW(54) },
        minWidth: { xs: pxToRem(62), md: pxTovW(100) },
        maxWidth: { xs: pxToRem(150), md: pxToRem(120), lg: pxTovW(150) }, // Added max-width
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: pxToRem(10), md: pxTovW(12) },
        borderRadius: { xs: '22px', md: '42px' },
        backgroundColor: backgroundColor || style.bgColor,
        color: style.textColor,
        border: `2px solid ${borderColor || style.borderColor}`,
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h3" // Changed to a more appropriate variant for titles
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 'bolder',
          color: textColor || style.textColor,
        }}
      >
        {className}
      </Typography>
    </Box>
  );
};

const ColorsArray = [
  {
    borderColor: '#0AA34F',
    bgColor: '#F0FDE0',
    textColor: '#0AA34F',
  },
  {
    borderColor: '#7D88FF',
    bgColor: '#F2F3FE',
    textColor: '#007CDC',
  },
  {
    borderColor: '#F8C807',
    bgColor: '#FFF3E7',
    textColor: '#F8C807',
  },
];
