import { Timestamp } from '@bufbuild/protobuf';
import { LessonPlanEventData } from '@protos/geneo_ai/ai.teacher.events.data_pb';
import { TeacherEvent } from '@protos/geneo_ai/ai.teacher.events_pb';
import { v4 as uuidv4 } from 'uuid';
import { saveMessageSession } from '../../storage';
import {
  ILessonCreateEventArgs,
  ILessonPlanCloseArgs,
  ILessonPlanEventArgs,
  ILessonPlanOpenArgs,
  ILessonUpdateEventArgs,
} from './inputArgs';
import {
  LessonPlanCloseArgsValidator,
  LessonPlanCreateEventArgsValidator,
  LessonPlanEventArgsValidator,
  LessonPlanOpenArgsValidator,
  LessonPlanUpdateEventArgsValidator,
} from './inputArgsValidator';
import { LESSON_PLAN_KEY } from './storageKeys';
import {
  checkNgetLessonPlanEventData,
  clearEventData,
  getLessonPlanEventData,
  getSessionDataWithIncrimentedIndex,
  hasEventData,
  timeDifference,
} from './utils';

export function lessonplanStorageKey(lessonId: string) {
  return `${LESSON_PLAN_KEY}!${lessonId}`;
}

export function lessonplanOpen(args: ILessonPlanOpenArgs): LessonPlanEventData {
  // const { error } = LessonPlanOpenArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { openTimestamp, lessonId } = args;
  const storageKey = lessonplanStorageKey(lessonId);
  const lessonplanData = getLessonPlanEventData(storageKey);
  const lessonplanSessionId = uuidv4();
  if (lessonplanData !== null) {
    throw new Error(
      `LessonPlan Data has to be null but ${JSON.stringify(
        lessonplanData
      )} is present"`
    );
  } else {
    const eventData = new LessonPlanEventData({
      lessonId,
      lessonplanSessionId: lessonplanSessionId,
      openTimestamp: Timestamp.fromDate(openTimestamp),
      actionId: lessonplanSessionId,
    });
    saveMessageSession(storageKey, eventData);
    return eventData;
  }
}

export function getLessonPlanOpenEvent(
  args: ILessonPlanEventArgs
): TeacherEvent {
  // const { error } = LessonPlanEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { lessonId, isOffline } = args;
  const storageKey = lessonplanStorageKey(lessonId);
  const eventData = checkNgetLessonPlanEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const lessonplanSessionData = getLessonPlanSessionData();
  // if (lessonplanSessionData.lessonPlan === undefined) {
  //   throw new Error(
  //     `lessonplan Session id can not be undefined in ${JSON.stringify(
  //       lessonplanSessionData
  //     )}`
  //   );
  // }
  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.openTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'lessonAction',
      value: {
        actionId: eventData.actionId,
        lessonId: lessonId,
        LessonPlanActionType: {
          case: 'open',
          value: true,
        },
      },
    },
  });
}

export function lessonplanClose(
  args: ILessonPlanCloseArgs
): LessonPlanEventData {
  // const { error } = LessonPlanCloseArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { closeTimestamp, lessonId } = args;
  const storageKey = lessonplanStorageKey(lessonId);
  const lessonplanData = getLessonPlanEventData(storageKey);
  if (!lessonplanData || lessonplanData.lessonId !== lessonId) {
    throw new Error(
      `LessonPlan Id in ${JSON.stringify(
        lessonplanData
      )} can't be different from provided "${lessonId}"`
    );
  } else {
    lessonplanData.closeTimestamp = Timestamp.fromDate(closeTimestamp);
    saveMessageSession(storageKey, lessonplanData);
    return lessonplanData;
  }
}

export function getLessonPlanCloseEvent(
  args: ILessonPlanEventArgs
): TeacherEvent {
  // const { error } = LessonPlanEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { lessonId, isOffline } = args;
  const storageKey = lessonplanStorageKey(lessonId);
  const eventData = checkNgetLessonPlanEventData(storageKey);
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const lessonplanSessionData = getLessonPlanSessionData();
  // if (lessonplanSessionData.lessonPlan === undefined) {
  //   throw new Error(
  //     `lessonplan Session id can not be undefined in ${JSON.stringify(
  //       lessonplanSessionData
  //     )}`
  //   );
  // }

  if (eventData.openTimestamp === undefined) {
    throw new Error(
      `openTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }
  if (eventData.closeTimestamp === undefined) {
    throw new Error(
      `closeTimestamp can not be undefined in ${JSON.stringify(eventData)}`
    );
  }

  const timespent: number = timeDifference(
    eventData.openTimestamp,
    eventData.closeTimestamp
  );

  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'lessonAction',
      value: {
        actionId: eventData.actionId,
        lessonId: lessonId,
        LessonPlanActionType: {
          case: 'close',
          value: {
            timespent,
          },
        },
      },
    },
  });
}

export function getLessonPlanCreateEvent(
  args: ILessonCreateEventArgs
): TeacherEvent {
  // const { error } = LessonPlanCreateEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { lessonId, name, resourceIds, isOffline } = args;
  const storageKey = lessonplanStorageKey(lessonId);
  const eventData = checkNgetLessonPlanEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const lessonplanSessionData = getLessonPlanSessionData();
  // if (lessonplanSessionData.lessonPlan === undefined) {
  //   throw new Error(
  //     `lessonplan Session id can not be undefined in ${JSON.stringify(
  //       lessonplanSessionData
  //     )}`
  //   );
  // }

  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'lessonAction',
      value: {
        actionId: eventData.actionId,
        lessonId: lessonId,
        LessonPlanActionType: {
          case: 'create',
          value: {
            name: name,
            resourceIds: resourceIds,
          },
        },
      },
    },
  });
}

export function getLessonPlanUpdateEvent(
  args: ILessonUpdateEventArgs
): TeacherEvent {
  // const { error } = LessonPlanUpdateEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { lessonId, resourceIds, newResourceIds, isOffline } = args;
  const storageKey = lessonplanStorageKey(lessonId);
  const eventData = checkNgetLessonPlanEventData(storageKey);
  const sessionData = getSessionDataWithIncrimentedIndex();
  // const lessonplanSessionData = getLessonPlanSessionData();
  // if (lessonplanSessionData.lessonPlan === undefined) {
  //   throw new Error(
  //     `lessonplan Session id can not be undefined in ${JSON.stringify(
  //       lessonplanSessionData
  //     )}`
  //   );
  // }

  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: eventData.closeTimestamp,
    eventIndex: sessionData.eventIndex,
    isOffline: isOffline,
    EventType: {
      case: 'lessonAction',
      value: {
        actionId: eventData.actionId,
        lessonId: lessonId,
        LessonPlanActionType: {
          case: 'update',
          value: {
            resourceIds: resourceIds,
            newResourceIds: newResourceIds,
          },
        },
      },
    },
  });
}

export function clearLessonPlanData(args: ILessonPlanEventArgs) {
  // const { error } = LessonPlanEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { lessonId } = args;
  const storageKey = lessonplanStorageKey(lessonId);
  clearEventData(storageKey);
}

export function isLessonPlanOpen(args: ILessonPlanEventArgs): boolean {
  // const { error } = LessonPlanEventArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { lessonId } = args;
  const storageKey = lessonplanStorageKey(lessonId);
  return hasEventData(storageKey);
}
