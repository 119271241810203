import {
  bookClose,
  bookOpen,
  clearBookData,
  getBookCloseEvent,
  getBookOpenEvent,
  isBookOpen,
} from '@events/teacher/eventsCreator/book';
import {
  initialiseBookSession,
  removeBookSession,
} from '@events/teacher/eventsCreator/session';

import { addEventRequest, isAiDebuggingEnabled } from '@geneo2-web/shared-ui';

//* --------------- Book Events ---------------
interface BookOpenEvent {
  bookId: number | undefined;
  bookSessionId: string;
  isOffline: boolean;
}
export const aiBookOpenEvent = async (props: BookOpenEvent) => {
  const { bookId, bookSessionId, isOffline } = props;
  try {
    if (bookId && !isBookOpen({ bookId: bookId })) {
      bookOpen({ openTimestamp: new Date(), bookId: bookId });
      initialiseBookSession({
        bookId: bookId,
        bookSessionId: bookSessionId,
      });

      const bookOpenEvent = getBookOpenEvent({
        bookId: bookId,
        isOffline: isOffline,
      });
      if (bookOpenEvent.timestamp) {
        await addEventRequest({
          key: bookOpenEvent.timestamp?.toJsonString(),
          value: bookOpenEvent.toJson(),
        });
      }
      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(bookOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

interface BookCloseEvent {
  bookId: number | undefined;
  isOffline: boolean;
}
export const aiBookCloseEvent = async (props: BookCloseEvent) => {
  const { bookId, isOffline } = props;
  try {
    if (bookId && isBookOpen({ bookId: bookId })) {
      bookClose({ closeTimestamp: new Date(), bookId: bookId });
      const bookCloseEvent = getBookCloseEvent({
        bookId: bookId,
        isOffline: isOffline,
      });
      if (bookCloseEvent.timestamp) {
        await addEventRequest({
          key: bookCloseEvent.timestamp?.toJsonString(),
          value: bookCloseEvent.toJson(),
        });
      }

      removeBookSession();
      clearBookData({ bookId: bookId });

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(bookCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};
