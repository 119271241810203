import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { Timestamp } from '@bufbuild/protobuf';
import {
  ImageWrapper,
  InfoDisplayPanel,
  InfoPopup,
  NewSectionList,
  PrimaryButton,
  deserify,
  getHumanReadableTimestampString,
  getLocalStorage,
  getMediaBasePath,
  getTeacherSubjectEnum,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
  theme,
  useFeatureEnabled,
  LockToggleButton,
  ContentLockPopup,
  getEnumKeyByEnumValue,
  cloneObject,
  setLocalStorage,
  getEnvConfig,
  IClassAndSubjectSelected,
} from '@geneo2-web/shared-ui';
import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  SessionModeEnum,
  SessionStatusEnum,
} from '@protos/learning_management/lms.db_pb';
import { TeacherLessonInfo } from '@protos/learning_management/lms.lesson.common.apis_pb';
import { useEffect, useRef, useState } from 'react';
import { useConnectedClassContext } from '../../../app/Context/ConnectedClassContextProvider';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  SEEALL_LESSON_PLAN_LIST,
  TEACHING_FLOW,
} from '../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../Auth/auth.events';
import { setBaseStationDetails } from '../../Teach/reducer/connectedClass.slice';
import {
  setLessonPlanRedirectionPath,
  setSelectedLessonInfo,
  setSessionMode,
  setUpdatedLessonSessionVisitedResourceInfo,
} from '../../Teach/reducer/teach.slice';
import SectionListSckeleton from '../shimmer';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { Module_ModuleCategoryEnum } from '@protos/content_management/content.db_pb';
import {
  ContentLockStatusType,
  Feature,
  LessonNodeLock,
} from '@protos/school_management/school.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import {
  setHomeLockStatus,
  setTeacherLessonList,
  setToastInfo,
} from '../reducer/homeDashboard.slice';

interface IlockedPopupData {
  lessonId?: string;
  image: string;
  noofTopics?: string;
  title: string;
  moduleId?: number;
  category?: Module_ModuleCategoryEnum;
  lockedStatus: ContentLockStatusType;
  sectionId?: number;
  subjectId?: number;
}

export default function HomeLessonPlansList() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const { isOffline } = useDownloadContext();
  const [lessonPlanList, setLessonPlanList] = useState<TeacherLessonInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const dispatch = useAppDispatch();
  const teacher_profile_id = getLocalStorage('userId');
  const { class_subject_info, teacher_lesson_list, home_page_lock_status } =
    deserify(useAppSelector((state) => state.homeDashboard));
  const location = useLocation();
  const config = getEnvConfig();
  const prevClassSubjectInfo = useRef<IClassAndSubjectSelected | undefined>(
    undefined
  );
  const isFirstRender = useRef(false); // Tracks if it's the initial render
  const { getBaseStationConnectionStatus, authenticateAnonymously } =
    useConnectedClassContext();
  const [selectedLP, setSelectedLP] = useState<TeacherLessonInfo | undefined>();
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const [completedResourceId, setCompletedResourceId] = useState<string[]>([]);
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const {
    LessonTeachAPIServiceV1ClientWithStatusCodeHandler,
    LmsCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  useEffect(() => {
    const lastFetchTime = getLocalStorage('homeAPILastTime'); // Retrieve the last fetch time
    const currentTime = new Date().getTime();
    const shouldFetchFromAPI =
      !lastFetchTime ||
      (lastFetchTime &&
        currentTime - Number(lastFetchTime) >=
          Number(config.homeExpirationTime));

    if (shouldFetchFromAPI || !teacher_lesson_list) {
      getTeacherLessonList(teacher_profile_id);
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = true;
      prevClassSubjectInfo.current = class_subject_info; // Initialize the ref
      return;
    }

    // Convert objects to strings for deep comparison
    const prevValueString = JSON.stringify(prevClassSubjectInfo.current);
    const currentValueString = JSON.stringify(class_subject_info);

    if (prevValueString !== currentValueString) {
      getTeacherLessonList(teacher_profile_id);
    }

    // Update the ref to the current value
    prevClassSubjectInfo.current = class_subject_info;
  }, [class_subject_info]);

  const [isContentLockPopupOpen, setIsContentLockPopupOpen] = useState(false);
  const [lockedPopupData, setLockedPopData] = useState<IlockedPopupData | null>(
    null
  );
  const featureInfo = user_info?.schoolDetails[0]?.featuresPlanInfo;
  const isContentLockFeature = useFeatureEnabled(
    featureInfo,
    Feature.CONTENT_LOCK
  );

  const getTeacherLessonList = async (teacherId: string) => {
    try {
      setLoading(true);
      const response =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.getTeacherLessonList(
          {
            teacherId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            sectionId: class_subject_info?.sectionId,
          }
        );
      const data = response.data;
      if (data) {
        const lpList = data.lessonList.sort(
          (a, b) =>
            (b.lastSessionTime?.toDate().getTime() ?? 0) -
            (a.lastSessionTime?.toDate().getTime() ?? 0)
        );
        const lessonPlanIds = lpList?.map((lesson) => lesson.lessonId);
        if (isContentLockFeature && teacherId) {
          await getLessonLockedStatus(teacherId, lessonPlanIds);
        }
        dispatch(setTeacherLessonList(lpList));
        // setLessonPlanList(lpList);
        setLocalStorage('homeAPILastTime', String(Date.now()));
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
      dispatch(setTeacherLessonList(undefined));
      console.log(err);
    }
  };

  const lessonPlanCardClickHandler = async (
    lessonInfo: TeacherLessonInfo | undefined,
    pathname: string,
    sessionMode: SessionModeEnum
  ) => {
    if (
      lessonInfo &&
      lessonInfo?.teachClassSubjects?.classId &&
      lessonInfo?.teachClassSubjects?.sectionId
    ) {
      dispatch(setSessionMode(sessionMode));
      if (sessionMode === SessionModeEnum.SESSION_MODE_TEACH) {
        const stationDetails = await getBaseStationConnectionStatus({
          teacherId: teacher_profile_id,
          classId: lessonInfo?.teachClassSubjects?.classId,
          sectionId: lessonInfo.teachClassSubjects?.sectionId,
        });
        if (stationDetails) {
          //deciding the connected classroom flow
          dispatch(setBaseStationDetails(stationDetails));
          authenticateAnonymously();
        }
      }
      dispatch(setSelectedLessonInfo(lessonInfo));
      dispatch(setLessonPlanRedirectionPath(pathname));
      const response =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.getPreviousLessonSessionInfo(
          {
            teacherId: BigInt(teacher_profile_id),
            lessonId: lessonInfo.lessonId,
          }
        );
      setCompletedResourceId(response.data?.completedResourceIds || []);
      const newSessionInfo =
        await LessonTeachAPIServiceV1ClientWithStatusCodeHandler.createTeacherLessonSession(
          {
            teacherId: BigInt(teacher_profile_id),
            lessonId: lessonInfo.lessonId,
            schoolId: user_info?.schoolDetails[0]?.schoolId,
            academicYear: 0,
            classId: class_subject_info?.classId,
            section: lessonInfo?.teachClassSubjects?.section,
            subject:
              lessonInfo.teachClassSubjects?.subjectId &&
              !isNaN(Number(lessonInfo.teachClassSubjects?.subjectId))
                ? getTeacherSubjectEnum(
                    Number(lessonInfo.teachClassSubjects?.subjectId),
                    user_info?.teachClassSubjects
                  )
                : undefined,
            // teacherLessonSessionId: ,
            sessionResourceIds: completedResourceId || [],
            startTime: Timestamp.fromDate(new Date()),
            sessionStatus: SessionStatusEnum.SESSION_STATUS_STARTED,
            sessionMode: sessionMode,
          }
        );
      if (newSessionInfo.teacherLessonSessionId) {
        dispatch(setUpdatedLessonSessionVisitedResourceInfo({}));
      }

      navigate({
        pathname: `${TEACHING_FLOW}/${lessonInfo.teachClassSubjects?.subjectId}/${lessonInfo.parentModuleId}/${lessonInfo.moduleId}/${lessonInfo.lessonId}`,
        search: `?${createSearchParams({
          classId: lessonInfo.teachClassSubjects?.classId?.toString() || '',
          section: lessonInfo.teachClassSubjects?.section || '',
          lessonSessionId: newSessionInfo.teacherLessonSessionId.toString(),
          sectionId: lessonInfo.teachClassSubjects?.sectionId.toString() || '',
        })}`,
      });
      await interactionEvent({
        url: 'Teacher_Home',
        context: 'Lesson_plans',
        name: 'LESSON_OPEN',
        isOffline: isOffline,
      });
    }
  };
  const iconDetails = (lesson: TeacherLessonInfo) => {
    const time = `${
      lesson.estimatedTimeInMin
        ? lesson.estimatedTimeInMin
        : 8 * lesson.resourceIds.length
    } Min`;
    // ${lesson.estimatedTimeInMin > 1 ? 's' : ''};
    return [
      { iconName: 'clock', text: time },
      { iconName: 'questions', text: `${lesson.resourceIds.length} resources` },
    ];
  };
  const getLessonLockedStatus = async (
    teacherId: string,
    lessonPlanIds: string[]
  ) => {
    try {
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.getLockStatusByIds(
          {
            personId: BigInt(teacherId),
            subjectId: class_subject_info?.subjectId,
            schoolClassSectionId: class_subject_info?.sectionId,
            lessonIds: lessonPlanIds,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          }
        );
      if (response) {
        dispatch(setHomeLockStatus(response.lessonInfo));
        // setContentLockData(response.lessonInfo);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
  const handleOpenPopup = (
    ev?: React.MouseEvent,
    lesson?: TeacherLessonInfo
  ) => {
    ev?.stopPropagation();
    if (lesson) {
      setLockedPopData({
        image: lesson?.posterImageUrl
          ? getMediaBasePath(lesson?.posterImageUrl, 'processedMediaBucket')
          : '',
        noofTopics: lesson?.resourceIds
          ? lesson?.resourceIds?.length.toString()
          : '',
        title: lesson?.title || '',
        moduleId: Number(lesson?.moduleId),
        category: Module_ModuleCategoryEnum.MODULE_CATEGORY_TOPIC,
        lessonId: lesson?.lessonId || '',
        lockedStatus:
          getLessonContentLockInfo(lesson.lessonId, home_page_lock_status)
            ?.lockStatus ||
          ContentLockStatusType.CONTENT_LOCK_STATUS_IS_UNLOCKED,
        subjectId: lesson.teachClassSubjects?.subjectId,
        sectionId: lesson.teachClassSubjects?.sectionId,
      });
    }
    setIsContentLockPopupOpen(true);
  };
  const handleClosePopup = () => {
    setIsContentLockPopupOpen(false);
    setLockedPopData(null);
  };
  const handleLockedRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = getEnumKeyByEnumValue(
      ContentLockStatusType,
      Number(event.target.value)
    );
    const currentData = cloneObject(lockedPopupData);
    if (val && currentData) {
      currentData.lockedStatus = ContentLockStatusType[val];
      setLockedPopData(currentData);
    }
  };

  const handleSubmit = async (ev?: React.MouseEvent) => {
    ev?.stopPropagation();
    try {
      // Ensure essential values are present before making the API call
      if (!lockedPopupData?.moduleId) {
        throw new Error('Module ID must be provided');
      }
      // Build the moduleInfo object dynamically, including resourceId only if it exists
      const moduleInfo = {
        moduleId: lockedPopupData?.moduleId,
        category: lockedPopupData?.category,
        lockStatus: lockedPopupData.lockedStatus,
        ...(lockedPopupData.lessonId && {
          lessonId: String(lockedPopupData.lessonId),
        }),
      };

      // Perform the API call
      const response =
        await LmsCommonAPIServiceV1ClientWithStatusCodeHandler.teacherContentLockUpdate(
          {
            teacherId: BigInt(teacher_profile_id),
            subjectId: lockedPopupData?.subjectId,
            schoolClassSectionId: lockedPopupData?.sectionId,
            moduleInfo,
          }
        );
      // Check if the response is valid and handle accordingly
      if (response) {
        await getTeacherLessonList(teacher_profile_id);
        handleClosePopup(); // Close the popup upon successful submission
        setLockedPopData(null); // Clear the lockedPopupData state
        // Refresh lesson lock status
      } else {
        dispatch(
          setToastInfo({
            label: 'Something went wrong',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      // Enhanced error handling
      console.error('Submission error:', error);

      // Display a user-friendly error message
      dispatch(
        setToastInfo({
          label: 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  return loading === true ? (
    <SectionListSckeleton />
  ) : (
    <Box>
      <NewSectionList
        noContentMessage="No Lesson Plans Available"
        isError={error}
        rightPanelWidth="1214"
        itemsPerPage={4}
        handleSeeAll={async () => {
          navigate(SEEALL_LESSON_PLAN_LIST);
          await interactionEvent({
            url: 'Teacher_home',
            context: 'lesson_plans',
            name: 'SEE_ALL',
            isOffline: isOffline,
          });
        }}
        sectionTitle="Lesson Plans"
        items={teacher_lesson_list?.slice(0, 20).map((lesson) => (
          <InfoDisplayPanel
            defaultImage="lessonplan-v1"
            variant={!isMobile && isIpad ? 'large' : 'small'}
            blueSubText={`${lesson.teachClassSubjects?.class}${lesson.teachClassSubjects?.section} - ${lesson.teachClassSubjects?.subject}`}
            mainHeading={`${lesson.title}`}
            image={getMediaBasePath(
              lesson.posterImageUrl,
              'processedMediaBucket'
            )}
            cardClickHandler={() => {
              setSelectedLP(lesson);
              setOpenPopUp(true);
            }}
            iconDetails={iconDetails(lesson)}
            isLocked={
              isContentLockFeature &&
              home_page_lock_status &&
              getLessonContentLockInfo(lesson.lessonId, home_page_lock_status)
                ?.lockStatus ===
                ContentLockStatusType.CONTENT_LOCK_STATUS_IS_LOCKED
                ? true
                : false
            }
            status={<InfoDisplayPanelStatus lesson={lesson} />}
          >
            <Box
              sx={{
                position: 'absolute',
                top: { xs: pxToRem(17), md: pxTovW(20) },
                right: { xs: pxToRem(18), md: pxTovW(20) },
              }}
            >
              {isContentLockFeature && (
                <LockToggleButton
                  isLocked={
                    home_page_lock_status
                      ? getLessonContentLockInfo(
                          lesson.lessonId,
                          home_page_lock_status
                        )?.lockStatus
                      : false
                  }
                  handleOpenPopup={(ev) => {
                    handleOpenPopup(ev, lesson);
                  }}
                />
              )}
            </Box>
          </InfoDisplayPanel>
        ))}
      />
      <InfoPopup
        iconName="homework2"
        popupText={[
          <Typography variant="h2">Do you want to select?</Typography>,
          <Box
            sx={{
              marginTop: { xs: pxToRem(41), lg: pxTovW(64) },
              display: 'flex',
              gap: { xs: pxToRem(20), lg: pxTovW(20) },
            }}
          >
            <PrimaryButton
              sx={{
                width: { xs: pxToRem(140), lg: pxTovW(226) },
                height: { xs: pxToRem(53), lg: pxTovW(85) },
              }}
              onClick={async () => {
                lessonPlanCardClickHandler(
                  selectedLP,
                  location.pathname,
                  SessionModeEnum.SESSION_MODE_TEACH
                );
                await interactionEvent({
                  url: 'Teacher_home',
                  context: 'lesson_plans',
                  name: 'TEACH',
                  isOffline: isOffline,
                });
              }}
            >
              <Typography variant="h2" color="white">
                TEACH
              </Typography>
            </PrimaryButton>
            <PrimaryButton
              sx={{
                width: { xs: pxToRem(140), lg: pxTovW(226) },
                height: { xs: pxToRem(53), lg: pxTovW(85) },
                backgroundColor: '#007CDC',
              }}
              onClick={async () => {
                lessonPlanCardClickHandler(
                  selectedLP,
                  location.pathname,
                  SessionModeEnum.SESSION_MODE_PREPARE_LESSON
                );
                await interactionEvent({
                  url: 'Teacher_home',
                  context: 'lesson_plans',
                  name: 'PREPARE',
                  isOffline: isOffline,
                });
              }}
            >
              <Typography variant="h2" color="white">
                PREPARE
              </Typography>
            </PrimaryButton>
          </Box>,
        ]}
        background="#007CDC"
        handleClose={() => {
          setSelectedLP(undefined);
          setOpenPopUp(false);
        }}
        open={openPopUp}
      />
      {lockedPopupData && (
        <ContentLockPopup
          open={isContentLockPopupOpen}
          onClose={handleClosePopup}
          onSubmit={(ev?: React.MouseEvent) =>
            lockedPopupData && handleSubmit(ev)
          }
          selectedValue={lockedPopupData.lockedStatus}
          onRadioChange={handleLockedRadioChange}
          lockDataObj={lockedPopupData}
        />
      )}
    </Box>
  );
}

interface InfoDisplayPanelStatus {
  lesson: TeacherLessonInfo;
}
const InfoDisplayPanelStatus = ({ lesson }: InfoDisplayPanelStatus) => {
  const taughtTime = getHumanReadableTimestampString(
    lesson.lastSessionTime
  )?.split(' ');

  const editTime = getHumanReadableTimestampString(lesson.modifiedOn)?.split(
    ' '
  );

  if (!lesson.teacherName) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: { xs: pxToRem(6), md: pxTovW(6) },
        paddingTop: { xs: pxToRem(5), md: pxTovW(5) },
        borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
      }}
    >
      {/* //! If Geneo created the Lesson it wont show Edited only Taught */}
      <ImageWrapper
        name={
          lesson.lastSessionTime
            ? 'book-black'
            : lesson.teacherName
            ? 'edit-pencil-black'
            : ''
        }
        type="png"
        parentFolder="icons"
        styles={{
          width: { xs: pxToRem(12), md: pxTovW(12) },
          height: { xs: pxToRem(12), md: pxTovW(12) },
        }}
      />
      {lesson.lastSessionTime ? (
        <Typography variant="smallText">
          {taughtTime && Number(taughtTime[0]) < 2
            ? 'Taught Today'
            : 'Taught ' + taughtTime?.join(' ')}
        </Typography>
      ) : (
        lesson.teacherName && (
          <Typography variant="smallText">
            {editTime && Number(editTime[0]) < 2
              ? 'Recently Edited'
              : 'Edited ' + editTime?.join(' ')}
          </Typography>
        )
      )}
    </Box>
  );
};
const getLessonContentLockInfo = (
  lessonId: string,
  contentLockData?: LessonNodeLock[]
) => {
  return contentLockData?.find((val) => val.lessonId === lessonId);
};
