import {
  ContentDetailCard,
  NoContentCard,
  SectionListWithTopCarousel,
  deserify,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  resourceTypeName,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, useMediaQuery } from '@mui/material';
import {
  ResourceCategoryEnum,
  Resource_ResourceEnum,
} from '@protos/content_management/content.db_pb';
import { SessionModeEnum } from '@protos/learning_management/lms.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../reduxStore/reduxHooks';
import { onResourceClick } from '../../../../utils/resource';
import { interactionEvent } from '../../../Auth/auth.events';
import { setResourceRedirectionPath } from '../../../Teach/reducer/teach.slice';
import { setChapterResources } from '../../reducer/homework.slice';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';

export default function ChapterResourcesDisplay() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { subject_id, chapter_id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
  const isIpadOnly = !isMobile && isIpad;
  const teacher_id = getLocalStorage('userId');
  const { isOffline } = useDownloadContext();
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const { chapter_resources } = deserify(
    useAppSelector((state) => state.homework)
  );
  const { class_subject_info } = deserify(
    useAppSelector((state) => state.homeDashboard)
  );
  const {
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
    ContentCommonAPIServiceV2ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();

  useEffect(() => {
    // if (chapter_id) fetchChapterTopicInfo();
    if (subject_id && chapter_id) {
      fetchChapterResources({ subjectId: subject_id, chapterId: chapter_id });
    }
  }, [chapter_id]);

  async function fetchChapterResources({
    subjectId,
    chapterId,
  }: {
    subjectId: string;
    chapterId: string;
  }) {
    try {
      setLoading('loading');

      const response =
        await ContentCommonAPIServiceV2ClientWithStatusCodeHandler.fetchChapterResources(
          {
            personId: BigInt(teacher_id),
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            subjectId: Number(subjectId),
            chapterId: Number(chapterId),
            sectionId: class_subject_info?.sectionId,
          }
        );

      if (response?.data) {
        const data = response.data;
        // console.log(typeof data, data);
        dispatch(setChapterResources(data));
        // console.log('data chapters', data);
      } else {
        // setError(new Error('Login failed'));
      }

      setLoading('completed');
    } catch (err) {
      setLoading('error');
      // setError(err);
      console.log(err);
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      {chapter_resources && chapter_resources.categoryResourceMap.length > 0 ? (
        <Box sx={{ width: '100%' }}>
          {chapter_resources.categoryResourceMap.map((resource) => (
            <SectionListWithTopCarousel
              title={resource.categoryTitle}
              subtitle={resource.categoryDescription}
              itemsToShow={isIpadOnly ? 2 : 3}
              containerMdWidth={isIpadOnly ? pxTovW(1000) : pxTovW(855)}
              items={resource.categoryResources.map((category) => (
                <ContentDetailCard
                  onClick={async () => {
                    await interactionEvent({
                      url: 'Teacher_hw_chapter_selection',
                      context: 'Chapter_resource',
                      name: 'REVISION_VIDEO_OPEN',
                      isOffline: isOffline,
                    });
                    dispatch(setResourceRedirectionPath(location.pathname));
                    onResourceClick(
                      navigate,
                      {
                        resourceId: category.resourceId,
                        subjectId: Number(subject_id),
                        chapterId: Number(chapter_id),
                      },
                      SessionModeEnum.SESSION_MODE_RESOURCE
                    );
                  }}
                  variant={isIpadOnly ? 'large' : 'small'}
                  image={getMediaBasePath(
                    category.posterImageUrl,
                    category.resourceCategoryType ===
                      ResourceCategoryEnum.RESOURCE_CATEGORY_MY_RESOURCES
                      ? 'userDataBucket'
                      : 'processedMediaBucket'
                  )}
                  heading={category.title}
                  iconDetails={[
                    {
                      iconName: 'clock',
                      text: category.estimatedTimeInMin.toString() + ' min',
                    },
                    {
                      iconName:
                        resourceTypeName(category.resourceType).icon &&
                        resourceTypeName(category.resourceType).icon,
                      text: resourceTypeName(category.resourceType).name,
                    },
                  ]}
                  showVideoIcon={
                    category.resourceType ===
                    Resource_ResourceEnum.RESOURCE_TYPE_VIDEO
                  }
                />
              ))}
            />
          ))}
        </Box>
      ) : (
        <NoContentCard
          variant="soon"
          icon="hourglass-web"
          text="Coming Soon!"
          rootStyle={{
            height: { xs: pxToRem(150), md: pxTovW(212) },
            boxSizing: 'border-box',
            mt: { xs: pxToRem(20), md: pxTovW(40) },
          }}
        />
      )}
    </Box>
  );
}
