import {
  ChipBadge,
  IStyles,
  NoContentCard,
  pxToRem,
  pxTovW,
  theme,
} from '@geneo2-web/shared-ui';
import {
  Box,
  Grid,
  Pagination,
  SxProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { interactionEvent } from '../../../../Auth/auth.events';
import { useGlobalContext } from 'apps/teacher/src/app/Context/GlobalContextProvider';
import { useDownloadContext } from '../../../../../app/Context/DownloadContextProviderV2';
interface SectionListProps {
  sectionTitle?: string;
  items?: React.ReactNode[];
  isError?: boolean;
  pageNumber?: number;
  setPageNumber?: React.Dispatch<React.SetStateAction<number>>;
  contentBoxSx?: SxProps;
  totalCount?: number;
  perPageCount?: number;
}

const styles: IStyles = {
  titleBox: {
    display: 'flex',
    gap: { xs: pxToRem(10), md: pxTovW(10), alignItems: 'center' },
    pl: { xs: pxToRem(20), md: pxTovW(0) },
  },
  contentBox: {
    boxSizing: 'border-box',
    p: { xs: pxToRem(20), md: pxTovW(20) },
    background: {
      xs: 'linear-gradient(to bottom, white 33%,#EAF4FC 33% 100%)',
      md: 'linear-gradient(to bottom, white 20%,#EAF4FC 20% 80%,white 80% 100%)',
    },
    width: { xs: '100vw', md: pxTovW(741) },
    height: { xs: 'max-content' },
    maxHeight: { md: '60vh', lg: '65vh' },
    overflow: 'scroll',
    '&::-webkit-scrollbar': { display: 'none' },
    // border: '1px solid red',
  },
  grid: {
    height: '100%',
    width: { xs: 'max-content', md: '100%' },
    // overflowX: 'scroll',
    justifyContent: 'space-between',
    pt: { xs: pxToRem(10), md: pxTovW(10) },
    gap: { xs: pxToRem(20), md: 0 },
  },
  gridItem: {
    justifyContent: 'center',
  },
};

export const ReadymadeHomeworkSection = (props: SectionListProps) => {
  const {
    sectionTitle,
    items = [],
    isError,
    pageNumber,
    setPageNumber,
    contentBoxSx,
    totalCount,
    perPageCount,
  } = props;
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { isIpadOnly } = useGlobalContext();
  const { isOffline } = useDownloadContext();
  let contentBoxStyles = { ...styles.contentBox };
  if (contentBoxSx) {
    contentBoxStyles = { ...styles.contentBox, ...contentBoxSx };
  }

  const getTotalPages = () => {
    return Math.ceil((totalCount || 0) / (perPageCount || 1));
  };

  return (
    <Box>
      <Box sx={styles.titleBox}>
        <Typography variant="h2" fontWeight="bold">
          {sectionTitle}
        </Typography>

        <ChipBadge
          label={totalCount ? totalCount : items?.length}
          color="primary"
          size="small"
          sx={{ fontSize: isIpadOnly ? pxToRem(14) : 'unset' }}
        />
      </Box>

      {isError ? (
        <Box sx={{ p: { xs: pxToRem(20), lg: pxTovW(40) } }}>
          {' '}
          <NoContentCard variant="error" icon="error" text="Error Occured" />
        </Box>
      ) : items?.length === 0 ? (
        <Box sx={{ width: '100%', p: { xs: pxToRem(20), md: pxTovW(40) } }}>
          {' '}
          <NoContentCard variant="info" icon="cards" text="No cards to show" />
        </Box>
      ) : (
        <Box sx={contentBoxStyles}>
          <Grid container sx={styles.grid}>
            {items?.map((item, index) => (
              <Grid
                key={index}
                item
                xs="auto"
                md={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <React.Fragment key={sectionTitle + '_' + index}>
                  {item}
                </React.Fragment>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {getTotalPages() > 0 ? (
        <Pagination
          page={pageNumber}
          // totalPages
          count={getTotalPages()}
          color="primary"
          onChange={async (event: React.ChangeEvent<unknown>, page: number) => {
            setPageNumber && setPageNumber(page);
            if (pageNumber !== page) {
              await interactionEvent({
                url: '',
                context: 'navigation_bar',
                name: 'PAGE_CHANGE',
                pathSegments: pathSegments,
                isOffline: isOffline,
              });
            }
          }}
          sx={{
            width: 'max-content',
            margin: 'auto',
            mt: { xs: pxToRem(20), md: pxTovW(30) },
            mb: { xs: pxToRem(20), md: pxTovW(0) },
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};
