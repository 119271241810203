import {
  IStyles,
  IconWrapper,
  InputFieldContainer,
  PrimaryButton,
  deserify,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Typography } from '@mui/material';

import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  SELF_REGISTRATION,
  SELF_REGISTRATION_FORM,
} from '../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../auth.events';
import { setSelfRegistrationData } from '../reducer/auth.slice';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';

const styles: IStyles = {
  registerBox: {
    height: { xs: '80vh', md: 'unset' },
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: { xs: 'flex-start', md: 'center' },
    alignItems: 'center',
    mt: { xs: 0, md: pxTovW(60) },
  },
  containerTop: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(0), md: pxTovW(0) },
  },
  phoneNumberBox: {
    backgroundColor: '#EEEEEE',
    borderRadius: { xs: pxToRem(10), md: pxTovW(10) },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: { xs: pxToRem(14), md: `${pxTovW(14)} ${pxTovW(21)}` },
    marginTop: { xs: pxToRem(5), md: pxTovW(20) },
  },
  editIcon: {
    height: { xs: pxToRem(29), md: pxTovW(48) },
    width: { xs: pxToRem(29), md: pxTovW(48) },
  },

  containerBottom: {
    width: { xs: '100%', md: pxTovW(517) },
    height: { xs: pxToRem(55), md: pxTovW(77) },
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: { xs: pxToRem(25), md: pxTovW(65) },
    marginTop: { xs: pxToRem(75), md: pxTovW(82) },
  },
  supportBox: {
    position: { xs: 'absolute', md: 'unset' },
    bottom: { xs: 0, md: 0 },
    width: '100%',
    bgcolor: 'neutral.turquoise',
    border: '1px solid',
    borderColor: 'secondary.main',
    borderRadius: { xs: pxToRem(25), md: pxTovW(25) },
    p: {
      xs: `${pxToRem(15)} ${pxToRem(10)}`,
      md: `${pxTovW(20)} ${pxTovW(10)}`,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: pxToRem(5), md: pxTovW(5) },
    boxSizing: 'border-box',
  },
};

export const UserNameRegistration = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const { setSelectedFunction } = useGlobalContext();
  const { isOffline } = useDownloadContext();

  const { self_registration_data } = deserify(
    useAppSelector((state) => state.auth)
  );

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const lastNameRef = useRef<HTMLInputElement | null>(null);

  const firstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value !== ' ' && setFirstName(event.target.value);
  };
  const lastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value !== ' ' && setLastName(event.target.value);
  };

  const nextClickHandler = async () => {
    dispatch(
      setSelfRegistrationData({
        ...self_registration_data,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
      })
    );
    navigate(SELF_REGISTRATION_FORM);
    await interactionEvent({
      url: '',
      context: 'navigation_bar',
      name: 'NEXT',
      pathSegments: pathSegments,
      isOffline: isOffline,
    });
  };

  const backButtonClick = () => {
    navigate(SELF_REGISTRATION);
  };
  useEffect(() => {
    setSelectedFunction(() => backButtonClick);

    self_registration_data?.first_name &&
      setFirstName(self_registration_data?.first_name);
    self_registration_data?.last_name &&
      setLastName(self_registration_data?.last_name);

    return () => {
      setSelectedFunction(null);
    };
  }, []);

  return (
    <Box sx={{ position: { md: 'relative' }, marginY: { md: 'auto' } }}>
      <Box
        onClick={backButtonClick}
        sx={{
          display: { xs: 'none', md: 'block' },
          position: { xs: 'unset', md: 'absolute' },
          top: { xs: 0, md: '-5%' },
          left: { xs: 0, md: '-8%' },
        }}
      >
        <KeyboardBackspaceIcon fontSize="large" sx={{ cursor: 'pointer' }} />
      </Box>

      <Box sx={styles.registerBox}>
        <Box sx={styles.containerTop}>
          <Box sx={{ textAlign: { md: 'center' } }}>
            <Typography variant="h1">Start Your</Typography>
            <Typography variant="h1" color="primary.main" fontWeight="bold">
              Teaching Journey
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: 'neutral.lightMint',
              p: {
                xs: `${pxToRem(10)} ${pxToRem(10)}`,
                md: `${pxTovW(14)} ${pxTovW(30)}`,
              },
              marginTop: { xs: pxToRem(20), md: pxTovW(44) },
            }}
          >
            <Typography variant="h4" color="secondary.main">
              Your phone number is verified
            </Typography>
          </Box>

          <Box sx={styles.phoneNumberBox}>
            <Typography variant="h3" fontWeight="bold">
              {self_registration_data?.phone_number}
            </Typography>
            <IconWrapper
              name="edit"
              parentFolder="icons"
              size="md"
              type="png"
              onClick={async () => {
                navigate(SELF_REGISTRATION);
                await interactionEvent({
                  url: '',
                  context: 'Phone_number',
                  name: 'EDIT',
                  pathSegments: pathSegments,
                  isOffline: isOffline,
                });
              }}
              customSx={styles.editIcon}
            />
          </Box>

          <Box
            sx={{
              marginTop: { xs: pxToRem(20), md: pxTovW(30) },
              gap: { xs: pxToRem(5), md: pxTovW(5) },
            }}
          >
            <InputFieldContainer
              helperTextvariant="error"
              topLabel="First Name"
              value={firstName}
              onChange={firstNameChange}
              // helperText={credentialError}
              onEnter={() =>
                firstName.length !== 0 && lastNameRef?.current?.focus()
              }
            />
            <InputFieldContainer
              helperTextvariant="error"
              topLabel="Last Name"
              value={lastName}
              onChange={lastNameChange}
              // helperText={credentialError}
              InputFieldRef={lastNameRef}
              onEnter={() => {
                if (firstName.length !== 0) nextClickHandler();
              }}
            />
          </Box>
        </Box>

        <Box sx={styles.containerBottom}>
          <PrimaryButton
            fullWidth
            onClick={() => nextClickHandler()}
            disabled={firstName.length === 0}
          >
            <Typography variant="h3" fontWeight="bold" color="common.white">
              Next
            </Typography>
          </PrimaryButton>
        </Box>

        {/* <ProfileVerifyStatusPopup
          modalState={modalState}
          closeModalState={() => {
            setModalState(false);
          }}
        /> */}
      </Box>
    </Box>
  );
};
