import { Timestamp } from '@bufbuild/protobuf';

import { IInteractionButtonEventArgs } from './inputArgs';

import { getSessionDataWithIncrimentedIndex } from './utils';

import { LOCAL_STORAGE } from '../../storage';

import { TeacherEvent } from '@protos/geneo_ai/ai.teacher.events_pb';

export function createInteractionButtonEvent(
  args: IInteractionButtonEventArgs
): TeacherEvent {
  // const { error } = InteractionButtonActionArgsValidator.validate(args);
  // if (error !== undefined) {
  //   throw error;
  // }
  const { url, name, context, lessonSessionId, resourceSessionId, isOffline } =
    args;
  const sessionData = getSessionDataWithIncrimentedIndex();
  return new TeacherEvent({
    teacherId: sessionData.teacherId,
    sessionId: sessionData.sessionId,
    timestamp: Timestamp.fromDate(new Date()),
    eventIndex: sessionData.eventIndex,
    activeSessionId: LOCAL_STORAGE.getItem('activeSession') ?? undefined,
    lessonSessionId: lessonSessionId,
    resourceSessionId: resourceSessionId,
    isOffline: isOffline,
    EventType: {
      case: 'interactionButton',
      value: {
        url,
        context,
        name,
      },
    },
  });
}
