import {
  HomeworkCard,
  IStyles,
  IconWrapper,
  ImageWrapper,
  InfoBar,
  PrimaryButton,
  cloneObject,
  deserify,
  firstLetterImage,
  getHumanReadableTimestampString,
  getLocalStorage,
  getMediaBasePath,
  pxToRem,
  pxTovW,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  HomeworkTask,
  HomeworksByModule,
} from '@protos/learning_management/lms.hw.common.apis_pb';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { styled } from '@mui/material/styles';
import { DifficultyLevelEnum } from '@protos/content_management/content.db_pb';
import { TaskEnum } from '@protos/learning_management/lms.db_pb';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../app/Context/GlobalContextProvider';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  HOMEWORK_CURRENT,
  HOMEWORK_CURRENT_CUSTOM,
} from '../../../routeHandling/RoutesNomenclature';
import {
  calculateMinMaxTime,
  totalNoQuestions,
} from '../../../utils/functions';
import { interactionEvent } from '../../Auth/auth.events';
import { ReadylessonShimmer } from '../../Teach/LessonPlanLists/shimmer';
import DifficultyNos from '../ReviewHomework/components/DifficultyNos';
import {
  convertToSequenceInfo,
  createTaskInfoModel,
} from '../ReviewHomework/functions';
import {
  resetCreatedHomeworkStatus,
  resetCustomHomework,
  resetDraftUploadedFiles,
  resetFetchedHwDetails,
  resetSelectedTasksInfo,
  setCreatedHomeworkStatus,
  setCustomHomework,
  setFetchedHwDetails,
  setModuleFilteredQuestions,
  setQuestionStats,
  setQuestionsCount,
  setSelectedTasksInfo,
} from '../reducer/homework.slice';
import { ReadymadeHomeworkSection } from './components/ReadymadeHw';
import { width } from '@mui/system';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

const styles: IStyles = {
  root: {
    bgcolor: 'neutral.paleGrey',
    paddingLeft: { xs: pxToRem(20), lg: pxTovW(241) },
    paddingRight: { xs: pxToRem(20), lg: pxTovW(241) },
    paddingTop: { xs: pxToRem(20), lg: pxTovW(40) },
    paddingBottom: { xs: pxToRem(20), lg: pxTovW(40) },
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(5), md: pxTovW(10) },
    paddingBottom: { xs: pxToRem(20), lg: pxTovW(40) },
  },
  addBox: {
    height: { xs: 'max-content', md: pxTovW(259) },
    width: { xs: '90vw', md: pxTovW(651) },
    borderRadius: { md: pxToRem(30) },
    backgroundColor: '#FFFFFF',
    border: { md: '1px solid #EAEAEA' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // gap: pxToRem(10),
    boxSizing: 'border-box',
    boxShadow: { md: `0px 3px ${pxToRem(35)} #0000000F` },
    padding: { xs: 0, md: pxTovW(40) },
    paddingTop: { xs: pxToRem(20), md: pxTovW(40) },
  },
  countBox: {
    height: { xs: pxToRem(79), md: pxTovW(141) },
    width: { xs: pxToRem(297), md: pxTovW(530.09) },
    borderRadius: pxToRem(10),
    backgroundColor: '#FFFFFF',
    border: '1px dashed #BEB8FD',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: pxToRem(10),
    boxSizing: 'border-box',
    boxShadow: `0px 3px ${pxToRem(35)} #0000000F`,
    padding: { xs: pxToRem(20), md: pxTovW(40) },
  },
  customButton: {
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    // WebkitLineClamp: 1,
    // WebkitBoxOrient: 'vertical',
    // overflow: 'hidden',
  },
};

export const CreateHomework = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { module_id } = useParams();

  const { class_subject_info } = deserify(
    useAppSelector((state) => state.homeDashboard)
  );
  const { questions_count, chapterwise_topic, questions_stats } = deserify(
    useAppSelector((state) => state.homework)
  );
  const dispatch = useAppDispatch();
  const teacher_id = getLocalStorage('userId');
  const pathSegments = location.pathname.split('/');
  const { setSelectedFunction } = useGlobalContext();
  const { isOffline } = useDownloadContext();
  const [hwLoading, setHwLoading] = useState(false);
  const [hwError, setHwError] = useState(false);
  const [quesLoading, setQuesLoading] = useState(false);
  const [quesError, setQuesError] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [homeworkList, sethomeworkList] = useState<
    HomeworksByModule | undefined
  >();

  const {
    LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler,
    LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  // console.log('location', location);

  useEffect(() => {
    dispatch(resetSelectedTasksInfo());
    dispatch(resetCreatedHomeworkStatus());
    dispatch(resetFetchedHwDetails());
    dispatch(resetCustomHomework());
    dispatch(resetDraftUploadedFiles());
    if (module_id) {
      fetchHomeworks(module_id);
      getModuleQuestionStats();
    }
    // console.log('module change', module_id);
  }, [module_id, pageNumber]);
  const fetchHomeworks = async (moduleId: string) => {
    try {
      setHwLoading(true);
      const response =
        await LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler.fetchHomeworksByModule(
          {
            personId: teacher_id,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            moduleId: Number(moduleId),
            pageNumber: pageNumber,
          }
        );
      if (response.data) {
        // console.log('fetchHomeworks', response.data);

        // const sortedData = response.data?.existingHomeworks.sort(
        //   (a, b) =>
        //     Number(b.lastModifiedTime?.seconds) -
        //     Number(a.lastModifiedTime?.seconds)
        // );

        // response.data.existingHomeworks = sortedData;

        sethomeworkList(response.data);
      }
      setHwLoading(false);
    } catch (err) {
      console.log(err);
      setHwLoading(false);
      setHwError(true);
    }
  };
  const getModuleQuestions = async (moduleId: string) => {
    try {
      setQuesLoading(true);
      const response =
        await LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler.getModuleQuestionsWithFilters(
          {
            teacherId: teacher_id,
            subjectId: class_subject_info?.subjectId,
            moduleId: Number(moduleId),
            requiredDifficultyLevelsCountInfo: questions_count,
          }
        );
      if (response.data) {
        const order = {
          [DifficultyLevelEnum.DIFFICULTY_LEVEL_LOW]: 0,
          [DifficultyLevelEnum.DIFFICULTY_LEVEL_MEDIUM]: 1,
          [DifficultyLevelEnum.DIFFICULTY_LEVEL_HIGH]: 2,
          [DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED]: 3,
        };
        const questionsCreated = cloneObject(response.data.questions).sort(
          (a, b) =>
            order[
              a.questionMeta?.difficultyLevel ||
                DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED
            ] -
            order[
              b.questionMeta?.difficultyLevel ||
                DifficultyLevelEnum.DIFFICULTY_LEVEL_UNDEFINED
            ]
        );
        dispatch(setModuleFilteredQuestions({ questions: questionsCreated }));
        const tasksInfo = createTaskInfoModel(questionsCreated);
        dispatch(setSelectedTasksInfo(tasksInfo));
      }
      setQuesLoading(false);
      navigate(`${HOMEWORK_CURRENT}/${moduleId}`);
    } catch (err) {
      console.log(err);
      setQuesLoading(false);
      setQuesError(true);
    }
  };
  const homeworkCardClickHandler = async (homeworkId: number) => {
    if (module_id) {
      await interactionEvent({
        url: 'Teacher_homework_create',
        context: 'Ready_homework',
        name: 'HOMEWORK_OPEN',
        isOffline: isOffline,
      });
      await getHomeworkDetails(homeworkId, module_id);
    }
  };
  const getHomeworkDetails = async (homeworkId: number, moduleId: string) => {
    try {
      const response =
        await LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler.fetchHomeworkContent(
          {
            personId: teacher_id,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            homeworkId: homeworkId,
          }
        );
      if (response.data) {
        const HwData = response.data;
        dispatch(setCreatedHomeworkStatus(HwData.homework?.taskCreationStatus));
        dispatch(
          setModuleFilteredQuestions({ questions: HwData.homeworkContent })
        );
        console.log(HwData.homework);
        dispatch(setFetchedHwDetails(HwData.homework));
        const sequenceInfo = convertToSequenceInfo(
          HwData.questionsSequenceInfo
        );
        if (sequenceInfo) {
          dispatch(setSelectedTasksInfo(sequenceInfo));
        }
        dispatch(setQuestionsCount());
        if (
          HwData?.homework?.taskType === TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM
        ) {
          const customHw = cloneObject(HwData.homework);
          customHw.homeworkId = 0;
          dispatch(setCustomHomework(customHw));
          navigate(`${HOMEWORK_CURRENT_CUSTOM}/${moduleId}`);
        } else {
          navigate(`${HOMEWORK_CURRENT}/${moduleId}`);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getModuleQuestionStats = async () => {
    try {
      const response =
        await LmsHomewokTeacherAPIServiceV1ClientWithStatusCodeHandler.getModuleQuestionStats(
          {
            moduleId: Number(module_id),
          }
        );
      if (response.data) {
        dispatch(setQuestionStats(response.data));
      }
    } catch (err) {
      console.log(err);
      setQuestionStats(undefined);
    }
  };
  const createClickHandler = async () => {
    await interactionEvent({
      url: 'Teacher_homework_create',
      context: 'Create_homework',
      name: 'CREATE',
      isOffline: isOffline,
    });

    if (module_id) {
      getModuleQuestions(module_id);
    }
  };
  const filteredTopic = (topicId: number) => {
    return chapterwise_topic?.chapterTopics.find((e) => e.topicId === topicId);
  };

  const redirectToCustomHomework = async () => {
    await interactionEvent({
      url: '',
      context: 'Teacher',
      name: 'CREATE_CUSTOM_HOMEWORK',
      pathSegments: pathSegments,
      isOffline: isOffline,
    });
    navigate(`${HOMEWORK_CURRENT_CUSTOM}/${module_id}`);
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Typography variant="h1">
          {filteredTopic(Number(module_id))?.topicTitle || 'Topic Name'}
        </Typography>
        <Typography variant="cardText" sx={{ color: '#007CDC' }}>
          Class {class_subject_info?.classname}
          {class_subject_info?.section} | {class_subject_info?.subject}
        </Typography>
      </Box>

      <Grid container columnSpacing={pxTovW(48)} rowSpacing={pxToRem(20)}>
        <Grid item xs={12} md={6}>
          {hwLoading === true ? (
            <ReadylessonShimmer />
          ) : (
            <ReadymadeHomeworkSection
              sectionTitle="Ready Homeworks"
              contentBoxSx={{
                width: { xs: '100vw', md: '100%' },
              }}
              items={homeworkList?.existingHomeworks.map((e) => (
                <HomeworkCard
                  homeworkTask={e}
                  submissionType={e.submissionType}
                  cardClickHandler={() =>
                    homeworkCardClickHandler(e.homeworkId)
                  }
                  status={<LessonPlanInfoPanelStatus homeworkTask={e} />}
                />
              ))}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalCount={homeworkList?.hwListInfo?.totalHwCount}
              perPageCount={homeworkList?.hwListInfo?.countInPagination}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6} sx={{}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: { xs: pxToRem(11), md: pxTovW(23) },
              gap: { xs: pxToRem(5), md: pxTovW(10), alignItems: 'start' },
            }}
          >
            <Typography variant="h2" fontWeight="bold">
              Create Homeworks
            </Typography>
            <Typography variant="h3" sx={{ color: '#6D6C6C' }}>
              Add Questions Across Level
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: { xs: pxToRem(25), md: pxTovW(40) },
            }}
          >
            <DifficultyNos mobileVariant="vertical" desktopVariant="vertical" />
            <InfoBar
              contentList={[
                {
                  iconName: 'questions',
                  heading: `${totalNoQuestions(questions_count)}`,
                  subHeading: 'Questions',
                },
                {
                  iconName: 'clock',
                  heading: `${calculateMinMaxTime(questions_count).min}-${
                    calculateMinMaxTime(questions_count).max
                  }`,
                  subHeading: 'Approx Mins',
                },
              ]}
              rootStyles={{ width: { xs: '100%', md: '80%' } }}
            />
            <PrimaryButton
              onClick={createClickHandler}
              disabled={
                !questions_stats ||
                (questions_count.noOfHighQuestions === 0 &&
                  questions_count.noOfLowQuestions === 0 &&
                  questions_count.noOfMediumQuestions === 0)
              }
            >
              CREATE
            </PrimaryButton>
            <Root>
              <Divider>
                <Chip label="OR" size="small" />
              </Divider>
            </Root>
            <PrimaryButton
              onClick={() => redirectToCustomHomework()}
              // sx={styles.customButton}
              sx={{
                backgroundColor: '#007CDC',
                color: '#FFFFFF',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                fontSize: {
                  xs: pxToRem(18),
                  md: pxToRem(14),
                  lg: pxTovW(21),
                },
                width: {
                  md: 'max-content',
                },
              }}
            >
              CREATE CUSTOM HOMEWORK
            </PrimaryButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

interface InfoDisplayPanelStatus {
  homeworkTask: HomeworkTask;
}
const LessonPlanInfoPanelStatus = ({
  homeworkTask,
}: InfoDisplayPanelStatus) => {
  const { user_info } = deserify(useAppSelector((state) => state.auth));

  const editTime = getHumanReadableTimestampString(
    homeworkTask?.lastModifiedTime
  )?.split(' ');

  return (
    <Box
      sx={{
        display: 'flex',
        height: { xs: pxToRem(30), md: pxTovW(30) },
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: { xs: pxToRem(20), md: pxTovW(20) },
        mt: { xs: pxToRem(10), md: pxTovW(10) },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: pxToRem(5),
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImageWrapper
          path={
            getMediaBasePath(homeworkTask?.teacherProfileImageUrl) ||
            firstLetterImage(homeworkTask?.teacherName || 'Geneo')
          }
          name={homeworkTask?.teacherName || 'Geneo'}
          type="png"
          parentFolder="tempAssets"
          styles={{
            height: { xs: pxToRem(18), md: pxTovW(28) },
            width: { xs: pxToRem(18), md: pxTovW(28) },
            borderRadius: '50%',
          }}
        />

        {/* <Typography variant="smallText" fontWeight="bold">
          {homeworkTask?.teacherName || 'Geneo'}
        </Typography> */}

        <Typography variant="smallText" fontWeight="bold">
          {user_info?.teacherProfileId.toString() ===
          homeworkTask?.teacherId?.toString()
            ? 'You'
            : homeworkTask.teacherName || 'Geneo'}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: { xs: pxToRem(2), md: pxTovW(2) },
          // wordWrap: 'break-word',
          alignItems: 'center',
          width: 'max-content',
        }}
      >
        <IconWrapper
          name="clock"
          size="small"
          parentFolder="icons"
          type="png"
        />
        {/* <Typography variant="smallText" color="#007CDC">
      {homeworkTask?.estimatedTimeInMin?.toString()}
    </Typography> */}
        <Typography variant="subText" color="text.disabled">
          {editTime && Number(editTime[0]) < 2
            ? 'Recently Edited'
            : 'Edited ' + editTime?.join(' ')}
        </Typography>
      </Box>
    </Box>
  );
};
