import {
  IStyles,
  ImageWrapper,
  OnlineStatusTag,
  SquareImageAvatar,
  deserify,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GetConnectedClassSessionAnalyticsResponse } from '@protos/learning_management/lms.connected.apis_pb';
import { useConnectedClassContext } from '../../../../app/Context/ConnectedClassContextProvider';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import { IStudentClickerDataObj } from '../../../Teach/reducer/connectedClass.slice';

const styles: IStyles = {
  root: {
    // width: { xs: '90vw', md: '100%' },
    width: { xs: '90vw', md: 'unset' },
    borderRadius: { xs: pxToRem(11), md: pxTovW(15) },
    backgroundColor: '#FFFFFFFF',
    boxSizing: 'border-box',
    boxShadow: {
      xs: `0px 0px ${pxToRem(11)} #00000029`,
      md: `0px 0px ${pxTovW(11)} #00000029`,
    },
    padding: {
      xs: `${pxToRem(12)} ${pxToRem(14)} ${pxToRem(12)} ${pxToRem(14)}`,
      md: `${pxTovW(0)} ${pxTovW(20)} ${pxTovW(20)} ${pxTovW(20)}`,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: { md: pxTovW(566) },
    '&  > :nth-last-child(-n+1) ': { borderBottom: 'none' },
    overflowY: 'scroll',
    '&::-webkit-scrollbar': { display: 'none' },
    marginBottom: { xs: pxToRem(11), md: pxTovW(15) },
  },

  dataBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: { xs: pxToRem(15), md: pxTovW(21) },
    paddingTop: { xs: pxToRem(15), md: pxTovW(21) },
    borderBottom: '1px solid lightGrey',
  },
  nameBox: {
    maxWidth: { xs: pxTovW(900), md: pxTovW(590) },
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(10), md: pxTovW(25), lg: pxTovW(12) },
  },
  nameTypo: {
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    maxHeight: pxToRem(40),
    maxWidth: '90%',
  },

  marksWrapper: {
    padding: {
      xs: `${pxToRem(5)} ${pxToRem(2)}`,
      md: `${pxTovW(7)} ${pxTovW(5)}`,
      lg: `${pxTovW(7)} ${pxTovW(1)}`,
    },
    // border: '1px solid red',
    textAlign: 'right',
    // borderRadius: {
    //   xs: pxToRem(16),
    //   md: pxTovW(35),
    // },
    // border: '1px solid #007CDC',
    // marginLeft: '10px',
  },
  marks: {
    fontWeight: 700,
    color: '#007CDC',
  },
  headingNameBox: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: pxToRem(10),
    paddingY: { xs: pxToRem(5), md: pxTovW(5) },
  },
};

interface IProps {
  resultData?: GetConnectedClassSessionAnalyticsResponse;
}
export const ResultsList = ({ resultData }: IProps) => {
  const { baseStationData } = useConnectedClassContext();
  const { students_data } = deserify(
    useAppSelector((state) => state.connectedClass)
  );

  const checkOnlineStatus = (clickerSerialNo: string) => {
    let returnData = false;
    baseStationData?.keyPads?.forEach((keypad: any) => {
      if (keypad.keypadSn === clickerSerialNo) {
        returnData = keypad.isOnline;
      }
    });
    return returnData ? 'online' : 'offline';
  };

  const findStudentMarks = (clickerSerialNo: string) => {
    let marks = 0;
    resultData?.studentAnalytics.map((studentData) => {
      if (clickerSerialNo === studentData.clickerId) marks = studentData.score;
    });

    return marks !== 1 ? `${marks} Marks` : `${marks} Mark`;
  };
  const findStudentResults = (clickerSerialNo: string) => {
    const studentData = resultData?.studentAnalytics.find(
      (student) => student.clickerId === clickerSerialNo
    );
    if (studentData) {
      return {
        marks: studentData.score,
        responseSubmissionTimeInMins: studentData.responseSubmissionTimeInMins,
      };
    }
    return { marks: 0, responseSubmissionTimeInMins: 0 };
  };

  const assignRanks = (students?: IStudentClickerDataObj[]) => {
    // Sort first by score (descending), then by response time (ascending)
    if (!students) {
      return undefined;
    }
    const sortedStudents = [...students].sort((a, b) => {
      if (
        findStudentResults(b.clickerSerialNo).marks !==
        findStudentResults(a.clickerSerialNo).marks
      ) {
        return (
          findStudentResults(b.clickerSerialNo).marks -
          findStudentResults(a.clickerSerialNo).marks
        ); // Higher score first
      }
      return (
        findStudentResults(a.clickerSerialNo).responseSubmissionTimeInMins -
        findStudentResults(b.clickerSerialNo).responseSubmissionTimeInMins
      ); // Lower time first
    });
    // Assign ranks, handling ties
    let rank = 1;
    let previousStudent: IStudentClickerDataObj | null = null;
    const studentsWithRank = sortedStudents.map((student, index) => {
      if (
        previousStudent &&
        findStudentResults(student.clickerSerialNo).marks ===
          findStudentResults(previousStudent.clickerSerialNo).marks &&
        findStudentResults(student.clickerSerialNo)
          .responseSubmissionTimeInMins ===
          findStudentResults(previousStudent.clickerSerialNo)
            .responseSubmissionTimeInMins
      ) {
        // Same rank for tied students
        student.rank = previousStudent.rank;
      } else {
        // New rank for non-tied student
        student.rank = rank;
        rank++; // Increment rank for the next student
      }
      // Store the previous student for tie-checking
      previousStudent = student;
      return student;
    });

    return studentsWithRank;
  };
  return (
    <Box sx={styles.root}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <Box sx={styles.headingNameBox}>
          <Typography variant="bodyText" fontWeight="bold">
            Rank
          </Typography>
        </Box>
        <Box sx={{ ...styles.headingNameBox, flexGrow: '1' }}>
          <Typography variant="bodyText" fontWeight="bold">
            Name
          </Typography>
        </Box>
        <Box sx={styles.headingNameBox}>
          <Typography variant="bodyText" fontWeight="bold">
            Marks
          </Typography>
        </Box>
        <Box
          sx={{
            ...styles.headingNameBox,
            paddingRight: { md: 0 },
          }}
        >
          <Typography variant="bodyText" fontWeight="bold">
            Time Taken
          </Typography>
        </Box>
      </Box>
      {/* {resultData?.studentAnalytics.map((student, index) => ( */}
      {assignRanks(students_data)?.map((student, index) => (
        <Box key={index} sx={styles.dataBox}>
          <Box sx={{ ...styles.nameBox, flexGrow: '1' }}>
            <Typography
              variant="bodyText"
              fontWeight="bold"
              sx={{ marginRight: pxToRem(10) }}
            >
              {student.rank}
            </Typography>
            {/* <ImageWrapper
              parentFolder="tempAssets"
              type="png"
              name="student1"
              styles={{
                height: { xs: pxToRem(36), md: pxTovW(48) },
                width: { xs: pxToRem(36), md: pxTovW(48) },
              }}
            /> */}
            <SquareImageAvatar
              size="small"
              name={student.studentName}
              online={
                checkOnlineStatus(student.clickerSerialNo) === 'online'
                  ? true
                  : false
              }
            />
            <Typography variant="h4" sx={styles.nameTypo}>
              {student.studentName}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: pxToRem(21), md: pxTovW(44) },
            }}
          >
            {/* <OnlineStatusTag
              status={checkOnlineStatus(student.clickerSerialNo)}
            /> */}
            <Box sx={styles.marksWrapper}>
              <Typography variant="h5" sx={styles.marks}>
                {findStudentMarks(student.clickerSerialNo)}
              </Typography>
            </Box>
            <Box sx={styles.marksWrapper}>
              <Typography variant="h5" sx={styles.marks}>
                {(
                  findStudentResults(student.clickerSerialNo)
                    .responseSubmissionTimeInMins * 60
                ).toFixed(0)}{' '}
                secs
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
