import { addEventRequest, isAiDebuggingEnabled } from '@geneo2-web/shared-ui';

import {
  chapterClose,
  chapterOpen,
  clearChapterData,
  getChapterCloseEvent,
  getChapterOpenEvent,
  isChapterOpen,
} from '@events/teacher/eventsCreator/chapter';
import {
  clearLessonPlanData,
  getLessonPlanCloseEvent,
  getLessonPlanCreateEvent,
  getLessonPlanOpenEvent,
  getLessonPlanUpdateEvent,
  isLessonPlanOpen,
  lessonplanClose,
  lessonplanOpen,
} from '@events/teacher/eventsCreator/lessonplan';
import {
  initialiseChapterSession,
  removeChapterSession,
} from '@events/teacher/eventsCreator/session';
import {
  clearTopicData,
  getTopicCloseEvent,
  getTopicOpenEvent,
  isTopicOpen,
  topicClose,
  topicOpen,
} from '@events/teacher/eventsCreator/topic';

//* --------------- Chapter Events ---------------
interface IChapterOpenEvent {
  chapterId: number | undefined;
  chapterSessionId: string;
  isOffline: boolean;
}
export const aiChapterOpenEvent = async (props: IChapterOpenEvent) => {
  const { chapterId, chapterSessionId, isOffline } = props;
  try {
    if (chapterId && !isChapterOpen({ chapterId: chapterId })) {
      chapterOpen({
        openTimestamp: new Date(),
        chapterId: chapterId,
      });

      initialiseChapterSession({
        chapterId: chapterId,
        chapterSessionId: chapterSessionId,
      });

      const chapterOpenEvent = getChapterOpenEvent({
        chapterId: chapterId,
        isOffline: isOffline,
      });

      if (chapterOpenEvent.timestamp) {
        await addEventRequest({
          key: chapterOpenEvent.timestamp?.toJsonString(),
          value: chapterOpenEvent.toJson(),
        });
      }

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(chapterOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

interface IChapterCloseEvent {
  chapterId: number | undefined;
  isOffline: boolean;
}
export const aiChapterCloseEvent = async (props: IChapterCloseEvent) => {
  const { chapterId, isOffline } = props;
  try {
    if (chapterId && isChapterOpen({ chapterId: chapterId })) {
      chapterClose({ closeTimestamp: new Date(), chapterId: chapterId });
      const chapterCloseEvent = getChapterCloseEvent({
        chapterId: chapterId,
        isOffline: isOffline,
      });
      if (chapterCloseEvent.timestamp) {
        await addEventRequest({
          key: chapterCloseEvent.timestamp?.toJsonString(),
          value: chapterCloseEvent.toJson(),
        });
      }

      removeChapterSession();
      clearChapterData({ chapterId: chapterId });

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(chapterCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Topic Events ---------------
interface ITopicEvent {
  topicId: number | undefined;
  isOffline: boolean;
}
export const aiTopicOpenEvent = async (props: ITopicEvent) => {
  const { topicId, isOffline } = props;
  try {
    if (topicId && !isTopicOpen({ topicId: topicId })) {
      topicOpen({
        openTimestamp: new Date(),
        topicId: topicId,
      });

      const topicOpenEvent = getTopicOpenEvent({
        topicId: topicId,
        isOffline: isOffline,
      });
      if (topicOpenEvent.timestamp) {
        await addEventRequest({
          key: topicOpenEvent.timestamp?.toJsonString(),
          value: topicOpenEvent.toJson(),
        });
      }
      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(topicOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiTopicCloseEvent = async (props: ITopicEvent) => {
  const { topicId, isOffline } = props;
  try {
    if (topicId && isTopicOpen({ topicId: topicId })) {
      topicClose({ closeTimestamp: new Date(), topicId: topicId });

      const topicCloseEvent = getTopicCloseEvent({
        topicId: topicId,
        isOffline: isOffline,
      });
      if (topicCloseEvent.timestamp) {
        await addEventRequest({
          key: topicCloseEvent.timestamp?.toJsonString(),
          value: topicCloseEvent.toJson(),
        });
      }

      clearTopicData({ topicId: topicId });

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(topicCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

//* --------------- Lesson Events ---------------
interface ILessonEvent {
  lessonId: string | undefined;
  isOffline: boolean;
}
export const aiLessonOpenEvent = async (props: ILessonEvent) => {
  const { lessonId, isOffline } = props;
  try {
    if (lessonId && !isLessonPlanOpen({ lessonId: lessonId })) {
      lessonplanOpen({
        openTimestamp: new Date(),
        lessonId: lessonId,
      });

      const lessonOpenEvent = getLessonPlanOpenEvent({
        lessonId: lessonId,
        isOffline: isOffline,
      });
      if (lessonOpenEvent.timestamp) {
        await addEventRequest({
          key: lessonOpenEvent.timestamp?.toJsonString(),
          value: lessonOpenEvent.toJson(),
        });
      }

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(lessonOpenEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

export const aiLessonCloseEvent = async (props: ILessonEvent) => {
  const { lessonId, isOffline } = props;
  try {
    if (lessonId && isLessonPlanOpen({ lessonId: lessonId })) {
      lessonplanClose({ closeTimestamp: new Date(), lessonId: lessonId });

      const lessonCloseEvent = getLessonPlanCloseEvent({
        lessonId: lessonId,
        isOffline: isOffline,
      });
      if (lessonCloseEvent.timestamp) {
        await addEventRequest({
          key: lessonCloseEvent.timestamp?.toJsonString(),
          value: lessonCloseEvent.toJson(),
        });
      }

      clearLessonPlanData({ lessonId: lessonId });

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(lessonCloseEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

interface ILessonCreate {
  lessonId: string;
  name: string;
  resourceIds: string[];
  isOffline: boolean;
}
export const aiLessonCreateEvent = async (props: ILessonCreate) => {
  const { lessonId, name, resourceIds, isOffline } = props;

  try {
    if (isLessonPlanOpen({ lessonId: lessonId })) {
      const lessonCreateEvent = getLessonPlanCreateEvent({
        lessonId: lessonId,
        name: name,
        resourceIds: resourceIds,
        isOffline: isOffline,
      });

      if (lessonCreateEvent.timestamp) {
        await addEventRequest({
          key: lessonCreateEvent.timestamp?.toJsonString(),
          value: lessonCreateEvent.toJson(),
        });
      }

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(lessonCreateEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};

interface ILessonUpdate {
  lessonId: string | undefined;
  resourceIds: string[] | undefined;
  newResourceIds?: string[];
  isOffline: boolean;
}
export const aiLessonUpdateEvent = async (props: ILessonUpdate) => {
  const { lessonId, resourceIds, newResourceIds, isOffline } = props;

  try {
    if (lessonId && resourceIds && isLessonPlanOpen({ lessonId: lessonId })) {
      const lessonUpdateEvent = getLessonPlanUpdateEvent({
        lessonId: lessonId,
        resourceIds: resourceIds,
        // newResourceIds: newResourceIds,
        newResourceIds: [],
        isOffline: isOffline,
      });

      if (lessonUpdateEvent.timestamp) {
        await addEventRequest({
          key: lessonUpdateEvent.timestamp?.toJsonString(),
          value: lessonUpdateEvent.toJson(),
        });
      }

      // await TeacherEventsAPIServiceV1Client.teacherEventProcess(lessonUpdateEvent);
    }
  } catch (error) {
    if (isAiDebuggingEnabled()) {
      throw error;
    } else {
      console.error(error);
    }
  }
};
