import { PlainMessage } from '@bufbuild/protobuf';
import {
  ChapterSelectedCard,
  IStyles,
  ImageWrapper,
  LessonPlanInfoPanel,
  convertBytesToHumanReadable,
  deleteDownloadedLessonPlan,
  deleteDownloadedTopic,
  deserify,
  findDownloadedSubjectByParams,
  firstLetterImage,
  getHumanReadableTimestampString,
  getSizeOfNode,
  pxToRem,
  pxTovW,
} from '@geneo2-web/shared-ui';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import {
  DownloadedLessonPlan,
  DownloadedSubject,
} from '@protos/content_management/content.db_pb';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../../app/Context/GlobalContextProvider';
import { DeleteButton } from '../../../../components/DeleteButton';
import { useAppSelector } from '../../../../reduxStore/reduxHooks';
import {
  OFFLINE_TEACHING_FLOW,
  OFFLINE_TEACH_TOPIC_SELECTION,
} from '../../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../../Auth/auth.events';
import { ReadymadeHomeworkSection } from '../../../Homework/CreateHomework/components/ReadymadeHw';
import { useDownloadContext } from '../../../../app/Context/DownloadContextProviderV2';
const styles: IStyles = {
  root: {
    boxSizing: 'border-box',
    backgroundColor: 'neutral.paleGrey',
    display: 'flex',
    flexDirection: 'column',
    gap: pxTovW(25),
    padding: { md: `${pxTovW(14)} ${pxTovW(240)}` },
    paddingTop: { md: pxTovW(40) },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(5), md: pxTovW(10) },
    paddingBottom: { xs: pxToRem(20), lg: pxTovW(20) },
    paddingLeft: { xs: pxToRem(20), md: 0 },
    paddingTop: { xs: pxToRem(20), md: 0 },
  },
  textWithBadge: {
    display: 'flex',
    alignItems: 'center',
    gap: { xs: pxToRem(14), md: pxTovW(10) },
    padding: {
      xs: `${pxToRem(20)} ${pxToRem(20)} ${pxToRem(5)} ${pxToRem(20)}`,
      md: 0,
    },
  },
  topicContainer: {
    flexBasis: { xs: '100%', md: '50%' },
    // maxWidth: { md: pxTovW(554) },
    // paddingLeft: { md: pxTovW(246) },
    // paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
    // backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chapterBox: {
    padding: { xs: pxToRem(20), md: 0 },
    paddingBottom: { xs: pxToRem(20), md: pxTovW(46) },
  },

  chooseTopicBox: {
    display: 'flex',
    flexDirection: 'column',
    // gap: { xs: pxToRem(25) },
    width: '50%',
    gap: { xs: pxToRem(7), md: pxTovW(18) },
    // backgroundColor: 'red',
  },
  topicBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    '& > div': { borderBottom: '1px solid #C6C6C6' },
  },
};

export const LessonPlanListOffline = () => {
  const navigate = useNavigate();
  const { subject_id, chapter_id, topic_id } = useParams();
  const subjectId = Number(subject_id);
  const chapterId = Number(chapter_id);
  const topicId = Number(topic_id);
  const { isOffline } = useDownloadContext();
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const { user_info } = deserify(useAppSelector((state) => state.auth));
  const [downloadedSubject, setDownloadedSubject] = useState<
    PlainMessage<DownloadedSubject>
  >(new DownloadedSubject());
  const chaps = downloadedSubject?.chapters || {};
  const selectedChapter = chaps[chapterId];
  const topics = selectedChapter?.downloadedTopics || {};
  const selectedTopic = topics[topicId];
  const lessonPlans = selectedTopic?.downloadedLessonPlans || {};
  // Filter out current section lesson plans
  const lessonPlansArray = Object.values(lessonPlans)
    .sort((a, b) => a.order - b.order)
    .filter(
      (val) =>
        class_subject_info?.sectionId &&
        val.schoolClassSectionId.includes(class_subject_info.sectionId) &&
        (!val.teacherId || val.teacherId == user_info?.teacherProfileId)
    );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { setSelectedFunction } = useGlobalContext();

  const backButtonClick = () => {
    navigate(`${OFFLINE_TEACH_TOPIC_SELECTION}/${subjectId}/${chapterId}`);
  };

  useEffect(() => {
    updateDownloadedSubjectData();
    setSelectedFunction(() => backButtonClick);
    return () => {
      setSelectedFunction(null);
    };
  }, []);

  const updateDownloadedSubjectData = async () => {
    const indexDbData = await findDownloadedSubjectByParams({
      subjectId: subjectId,
    });
    if (indexDbData.length > 0) {
      const data = indexDbData[0];
      setDownloadedSubject(data);
    }
  };
  return (
    <Box sx={styles.root}>
      {/* <ConfigSelector /> */}
      <Box sx={styles.topicContainer}>
        <Box sx={styles.chapterBox}>
          <Box sx={styles.header}>
            <Typography variant="h1" sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              // width: '90%',
            }}>
              {selectedTopic?.topicName}
            </Typography>
            <Typography variant="cardText" sx={{ color: '#007CDC' }}>
              {/* Class 8A | Science */}
              {class_subject_info
                ? 'Class ' +
                class_subject_info?.classname +
                class_subject_info?.section +
                ' | ' +
                class_subject_info?.subject
                : ''}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              gap: pxTovW(15),
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: {
                  xs: `${pxToRem(5)} ${pxToRem(6)}`,
                  md: `${pxTovW(11)} ${pxTovW(19)}`,
                },
                backgroundColor: '#FFE3E7',
                border: '1px solid #FF6177',
                borderRadius: {
                  xs: pxToRem(7),
                  md: pxTovW(7),
                },
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h4" fontWeight="medium">
                {convertBytesToHumanReadable(
                  getSizeOfNode(
                    downloadedSubject,
                    chapterId,
                    topicId,
                    undefined,
                    user_info?.teacherProfileId
                  )
                )}
              </Typography>
            </Box>
            <DeleteButton
              title={selectedTopic?.topicName}
              onClick={async () => {
                if (isNaN(subjectId) || isNaN(chapterId) || isNaN(topicId)) {
                  return;
                }
                await deleteDownloadedTopic({
                  subjectId,
                  chapterId,
                  topicId,
                  sectionId: class_subject_info?.sectionId,
                  userId: user_info?.teacherProfileId.toString(),
                });
                navigate(
                  `${OFFLINE_TEACH_TOPIC_SELECTION}/${subjectId}/${chapterId}`
                );
                await interactionEvent({
                  url: 'Teacher_lesson_plan_lists',
                  context: 'topic',
                  name: 'DELETE',
                  isOffline: isOffline,
                });
              }}
              rootStyle={{
                height: {
                  xs: pxToRem(26),
                  md: pxTovW(45),
                },
                width: {
                  xs: pxToRem(26),
                  md: pxTovW(45),
                },
                marginLeft: {
                  xs: pxToRem(5),
                  md: pxTovW(10),
                },
                marginRight: {
                  xs: pxToRem(10),
                  md: pxTovW(10),
                },
              }}
            />
          </Box>
          {/* {isMobile &&
            <Typography sx={{ marginRight: pxToRem(10), marginTop: pxToRem(6) }} variant='body1'>Expires in 20d</Typography>
          } */}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          //   backgroundColor: 'red',
        }}
      >
        <ReadymadeHomeworkSection
          sectionTitle="Ready Lesson Plans"
          items={lessonPlansArray.map((lessonPlan) => (
            <LessonPlanInfoPanel
              variant="large"
              image={lessonPlan.image}
              mainHeading={lessonPlan.lessonPlanTitle}
              onClick={async () => {
                navigate(
                  `${OFFLINE_TEACHING_FLOW}/${subjectId}/${chapterId}/${topicId}/${lessonPlan.lessonPlanId}`
                );
                await interactionEvent({
                  url: 'Teacher_lesson_plan_lists',
                  context: 'Ready_lesson_plan',
                  name: 'LESSON_PLAN_OPEN',
                  isOffline: isOffline,
                });
              }}
              iconDetails={[
                {
                  iconName: 'clock',
                  text: `${lessonPlan.estimatedTimeInMin} Min`,
                },
                {
                  iconName: 'questions',
                  text: `${Object.keys(lessonPlan.downloadedResources).length
                    } resource`,
                },
              ]}
              status={<LessonPlanInfoPanelStatus lessonPlan={lessonPlan} />}
              rootStyle={{
                width: { xs: pxToRem(199), md: pxTovW(295) },
                height: { xs: pxToRem(241), md: pxTovW(332) },
              }}
            >
              <DeleteButton
                title={lessonPlan.lessonPlanTitle}
                onClick={async () => {
                  if (
                    isNaN(subjectId) ||
                    isNaN(chapterId) ||
                    isNaN(topicId) ||
                    !lessonPlan.lessonPlanId
                  ) {
                    return;
                  }
                  await deleteDownloadedLessonPlan({
                    subjectId,
                    chapterId,
                    topicId,
                    lessonId: lessonPlan.lessonPlanId,
                    sectionId: class_subject_info?.sectionId,
                    userId: user_info?.teacherProfileId.toString(),
                  });
                  await updateDownloadedSubjectData();
                  await interactionEvent({
                    url: 'Teacher_lesson_plan_lists',
                    context: 'Ready_lesson_plan',
                    name: 'DELETE',
                    isOffline: isOffline,
                  });
                }}
                rootStyle={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  zIndex: '100',
                }}
              />
            </LessonPlanInfoPanel>
          ))}
        />
      </Box>
    </Box>
  );
};

const LessonPlanInfoPanelStatus = ({
  lessonPlan,
}: {
  lessonPlan: PlainMessage<DownloadedLessonPlan>;
}) => {
  const user_info = deserify(useAppSelector((state) => state.auth.user_info));
  const syncTime = getHumanReadableTimestampString(
    lessonPlan.lastUpdatedAt
  )?.split(' ');

  if (!lessonPlan.teacherName) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `${pxTovW(2)} solid #E7E7E7D9`,
        pt: { xs: pxToRem(5), md: pxTovW(5) },
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: pxToRem(5), md: pxTovW(5) },
        }}
      >
        <ImageWrapper
          name={lessonPlan.teacherName || 'Geneo'}
          type="png"
          parentFolder="icons"
          styles={{
            width: { xs: pxToRem(18), md: pxTovW(28) },
            height: { xs: pxToRem(18), md: pxTovW(28) },
            borderRadius: '50%',
          }}
          path={
            firstLetterImage(lessonPlan.teacherName) ||
            firstLetterImage('Geneo')
          }
        />

        <Typography variant="smallText" fontWeight="bold">
          {lessonPlan.teacherId &&
            user_info?.teacherProfileId.toString() ===
            lessonPlan.teacherId.toString()
            ? 'You'
            : lessonPlan.teacherName || 'Geneo'}
        </Typography>
      </Box>

      {syncTime && (
        <Typography variant="subText" color="text.disabled">
          {Number(syncTime[0]) < 2
            ? 'Synced Today'
            : 'Synced ' + syncTime?.join(' ')}
        </Typography>
      )}
    </Box>
  );
};
