import {
  AssessmentCard,
  deserify,
  FilterSortPopup,
  getLocalStorage,
  IStyles,
  Loader,
  NoContentCard,
  pxToRem,
  pxTovW,
  roundingAssessmentNumber,
  SelectMenu,
  ShimmerManageHwCard,
  theme,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import {
  Chip,
  Divider,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box, Container, display, SxProps } from '@mui/system';
import React, { useEffect, useState, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../../reduxStore/reduxHooks';
import { ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import {
  setAssessmentData,
  setSelectedChapter,
  setSelectedTopic,
  resetSelectedChapter,
  resetSelectedTopic,
  setCourseIndexInfo,
} from '../reducer/assessmentFlow.slice';
import {
  AssessmentEnum,
  TaskEnum,
} from '@protos/learning_management/lms.db_pb';
import { DifficultyLevelEnum } from '@protos/content_management/content.db_pb';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';
import { HomeworkTask } from '@protos/learning_management/lms.hw.common.apis_pb';
import {
  setCreatedHomeworkStatus,
  setCustomHomework,
  setFetchedHwDetails,
  setModuleFilteredQuestions,
  setQuestionsCount,
  setSelectedTasksInfo,
} from '../../Homework/reducer/homework.slice';
import { convertToSequenceInfo } from '../../Homework/ReviewHomework/functions';
import {
  HOMEWORK_CURRENT,
  HOMEWORK_CURRENT_CUSTOM,
} from '../../../routeHandling/RoutesNomenclature';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssessmentsByType } from '@protos/learning_management/lms.assessment.apis_pb';

const styles: IStyles = {
  root: {
    width: '100vw',
    cursor: 'pointer',
    paddingLeft: { xs: pxToRem(20), lg: pxTovW(241) },
    paddingTop: { xs: pxToRem(20), lg: pxTovW(40) },
    paddingBottom: { xs: pxToRem(50), lg: pxTovW(40) },
    paddingRight: { xs: pxToRem(20), lg: pxTovW(241) },
    boxSizing: 'border-box',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  pagination: {
    width: 'max-content',
    margin: 'auto',
    mt: { xs: pxToRem(20), md: pxTovW(30) },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  inputFeild: {
    backgroundColor: 'red',
    display: 'flex',
    height: { xs: pxToRem(50), md: pxTovW(56) },
    borderRadius: { xs: pxToRem(10), md: pxTovW(15) },
    border: '1px solid #CCE6FE',
    width: { xs: pxToRem(311), lg: pxTovW(457) },
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  inputBox: {
    display: 'flex',
    justifyContent: { xs: 'space-between', md: 'center' },
    gap: { xs: pxToRem(10), md: pxTovW(10) },
  },
  inputFeildBox: {
    paddingTop: { xs: pxToRem(20), md: pxTovW(5) },
    paddingLeft: { xs: pxToRem(10), md: pxTovW(70) },
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: pxToRem(6), md: pxTovW(12) },
  },
};
const AssesmentLibrary: React.FC = () => {
  const { LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();
  const navigate = useNavigate();
  const [selectedAssessmentType, setSelectedAssessmentType] = useState(
    'Homework'
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [paginatedData, setPaginatedData] = useState<HomeworkTask[]>(); // Data for current page
  const itemsPerPage = 24; // Items per page

  const dispatch = useAppDispatch();
  // const isMounted = useRef(false);
  const {
    assesment_flow_data,
    chapter_topic_data,
    selected_chapter,
    selected_topic,
  } = useAppSelector((state) => state.assessment);
  const { class_subject_info } = useAppSelector((state) => state.homeDashboard);
  const {
    LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler,
    ContentCommonAPIServiceV1ClientWithStatusCodeHandler,
  } = useCommonServiceClientContext();
  const teacher_id = getLocalStorage('userId');

  //make a switch case that it will return a value based on the selectedAssessmentType
  const assessmentType =
    {
      'Formative': AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT,
      'Homework': AssessmentEnum.ASSESSMENT_HOMEWORK,
      // Diagnostics: AssessmentEnum.ASSESSMENT_DIAGNOSTICS,
    }[selectedAssessmentType] || AssessmentEnum.ASSESSMENT_HOMEWORK;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const calculatePaginationInfo = (
    assessmentData: AssessmentsByType | undefined,
    assessmentType: AssessmentEnum
  ): void => {
    const countInPagination =
      assessmentData?.assessmentListInfo?.countInPagination || 1;
    const hwCount = assessmentData?.assessmentListInfo?.homeworksCount || 0;
    const assessmentCount =
      assessmentData?.assessmentListInfo?.totalAssessmentsCount || 0;

    // Set totalCount if assessmentCount is greater than 0
    if (assessmentCount > 0) {
      setTotalCount(assessmentCount);
    }
    else {
      setTotalCount(undefined);
    }

    // // Calculate totalPages based on assessmentType
    // const totalPages =
    //   assessmentType === AssessmentEnum.ASSESSMENT_HOMEWORK
    //     ? Math.ceil(hwCount / countInPagination)
    //     : Math.ceil(assessmentCount / countInPagination);

    // Calculate totalPages based on itemsPerPage
    const totalPages = Math.ceil(assessmentCount / countInPagination);
    setTotalPages(totalPages);
  };
  // need this when pagination implemented
  // useEffect(() => {
  //   console.log(selected_topic, 'selected_topic');
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true); // Start loading
  //       // First API call: Fetch assessments
  //       const assessmentResult = await fetchAssessmentsByModule(
  //         selected_chapter?.chapterId,
  //         selected_topic?.topicId
  //       );
  //       if (assessmentResult) {
  //         dispatch(setAssessmentData(assessmentResult.data));
  //         calculatePaginationInfo(assessmentResult?.data, assessmentType);
  //       }
  //     } catch (error) {
  //       setError(error); // Handle error
  //     } finally {
  //       setLoading(false); // Stop loading
  //     }
  //   };

  //   if (isMounted.current) {
  //     fetchData();
  //   } else {
  //     isMounted.current = true;
  //   }
  // }, [selected_chapter, selected_topic]); // Runs when either selected_chapter or selected_topic changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        // Define the fetchAssessmentsByModule function inside useEffect
        // First API call: Fetch assessments
        const assessmentResult = await fetchAssessmentsByModule(
          selected_chapter?.chapterId,
          selected_topic?.topicId
        );
        if (assessmentResult) {
          const fullData = assessmentResult?.data?.assessments || [];

          // Set full data in Redux or other stores
          dispatch(setAssessmentData(assessmentResult.data));
          calculatePaginationInfo(assessmentResult?.data, assessmentType);

          // Initialize paginated data for the first page
          const initialData = fullData.slice(0, itemsPerPage);
          setPaginatedData(initialData);
        }
      } catch (error) {
        setError(error); // Handle error
      } finally {
        setLoading(false); // Stop loading
      }
    };
    fetchData();
  }, [selected_chapter, selected_topic, pageNumber]); // Runs when either selected_chapter or selected_topic changes

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);

    // Update paginated data for the selected page
    const fullData = assesment_flow_data?.assessments || [];
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageData = fullData.slice(startIndex, endIndex);
    setPaginatedData(pageData);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        setError(null); // Reset previous error if any
        setPageNumber(1);
        if (assesment_flow_data) {
          calculatePaginationInfo(assesment_flow_data, assessmentType);
        }
        const chapterTopicData =
          await ContentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchCourseIndexInfo(
            {
              personId: BigInt(teacher_id),
              personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
              subjectId: Number(class_subject_info?.subjectId),
            }
          );
        if (chapterTopicData) {
          dispatch(setCourseIndexInfo(chapterTopicData?.data));
        }
      } catch (error) {
        dispatch(
          setToastInfo({
            label: 'Error fetching Chapter Topic Data',
            variant: 'error',
            open: true,
          })
        );
      } finally {
        setLoading(false); // Stop loading
      }
    };
    fetchData();
  }, []);

  const fetchAssessmentsByModule = async (
    chapterId?: number,
    topicId?: number
  ) => {
    const response =
      await LmsAssessmentCommonAPIServiceV1ClientWithStatusCodeHandler.fetchAssessmentsByModule(
        {
          personId: teacher_id,
          personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
          subjectId: class_subject_info?.subjectId,
          chapterId: chapterId ? Number(chapterId) : undefined,
          topicId: topicId ? Number(topicId) : undefined,
          assessmentType: assessmentType,
          pageNumber: pageNumber,
        }
      );
    return response; // Return the fetched data or handle it here
  };

  const sortFunction = async (sortValue: string) => {
    if (assesment_flow_data?.assessments && paginatedData) {
      const temp = assesment_flow_data?.assessments?.slice(); // Create a shallow copy for immutability
      let sortedData = [];

      switch (sortValue) {
        case 'Alphabetical: A to Z':
          sortedData = temp.sort((a, b) =>
            a.homeworkTitle.localeCompare(b.homeworkTitle)
          );
          break;
        case 'Alphabetical: Z to A':
          sortedData = temp.sort((a, b) =>
            b.homeworkTitle.localeCompare(a.homeworkTitle)
          );
          break;
        case 'Newest':
          sortedData = temp.sort((a, b) => {
            const timeA = a?.lastModifiedTime?.seconds || 0; // Use as-is, could be BigInt or number
            const timeB = b?.lastModifiedTime?.seconds || 0; // Use as-is, could be BigInt or number
            return timeB > timeA ? 1 : timeB < timeA ? -1 : 0; // Compare values
          });
          break;
        case 'Oldest':
          sortedData = temp.sort((a, b) => {
            const timeA = a?.lastModifiedTime?.seconds || 0; // Use as-is, could be BigInt or number
            const timeB = b?.lastModifiedTime?.seconds || 0; // Use as-is, could be BigInt or number
            return timeA > timeB ? 1 : timeA < timeB ? -1 : 0; // Compare values
          });
          break;
        case 'Duration: Longest to Shortest':
          sortedData = temp.sort(
            (a, b) => (b.estimatedTimeInMin || 0) - (a.estimatedTimeInMin || 0)
          );
          break;
        case 'Duration: Shortest to Longest':
          sortedData = temp.sort(
            (a, b) => (a.estimatedTimeInMin || 0) - (b.estimatedTimeInMin || 0)
          );
          break;
        case 'Difficulty: Easy to Hard':
          sortedData = temp.sort(
            (a, b) =>
              difficultyLevelSort(a.difficultyLevel) -
              difficultyLevelSort(b.difficultyLevel)
          );
          break;
        case 'Difficulty: Hard to Easy':
          sortedData = temp.sort(
            (a, b) =>
              difficultyLevelSort(b.difficultyLevel) -
              difficultyLevelSort(a.difficultyLevel)
          );
          break;
        default:
          sortedData = temp;
      }
      const startIndex = (currentPage - 1) * 24;
      const endIndex = startIndex + 24;
      const currentPageData = sortedData.slice(startIndex, endIndex);

      // Update paginatedData and store
      setPaginatedData(currentPageData);
      dispatch(
        setAssessmentData({
          ...assesment_flow_data,
          assessments: sortedData, // Update the full sorted data in the store
        })
      );
      // setPaginatedData(sortedData);
      // dispatch(
      //   setAssessmentData({
      //     ...assesment_flow_data,
      //     assessments: sortedData,
      //   })
      // );
    }
  };
  const cardClickHandler = async (cardDetails: HomeworkTask) => {
    if (cardDetails.moduleId) {
      await getHomeworkDetails(
        cardDetails.homeworkId,
        cardDetails.moduleId.toString()
      );
    }
  };

  const getHomeworkDetails = async (homeworkId: number, moduleId: string) => {
    try {
      const response =
        await LmsHomeworkCommonAPIServiceV1ClientWithStatusCodeHandler.fetchHomeworkContent(
          {
            personId: teacher_id,
            personType: ProfileRolesEnum.PROFILE_ROLE_TEACHER,
            homeworkId: homeworkId,
          }
        );
      if (response.data) {
        const HwData = response.data;
        dispatch(setCreatedHomeworkStatus(HwData.homework?.taskCreationStatus));
        dispatch(
          setModuleFilteredQuestions({ questions: HwData.homeworkContent })
        );
        console.log(HwData.homework);
        dispatch(setFetchedHwDetails(HwData.homework));
        const sequenceInfo = convertToSequenceInfo(
          HwData.questionsSequenceInfo
        );
        if (sequenceInfo) {
          dispatch(setSelectedTasksInfo(sequenceInfo));
        }
        dispatch(setQuestionsCount());
        if (
          HwData?.homework?.taskType === TaskEnum.TASK_HOMEWORK_TEACHER_CUSTOM
        ) {
          const customHw = structuredClone(HwData.homework);
          customHw.homeworkId = 0;
          dispatch(setCustomHomework(customHw));
          navigate(`${HOMEWORK_CURRENT_CUSTOM}/${moduleId}`);
        } else {
          navigate(`${HOMEWORK_CURRENT}/${moduleId}`);
        }
      } else {
        dispatch(
          setToastInfo({
            label: 'Something went wrong!',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        setToastInfo({
          label: 'Something went wrong!',
          variant: 'error',
          open: true,
        })
      );
    }
  };
  // Helper function for sorting difficulty levels
  const difficultyLevelSort = (level: number) => {
    switch (level) {
      case DifficultyLevelEnum.DIFFICULTY_LEVEL_HIGH:
        return 3;
      case DifficultyLevelEnum.DIFFICULTY_LEVEL_MEDIUM:
        return 2;
      case DifficultyLevelEnum.DIFFICULTY_LEVEL_LOW:
        return 1;
      default:
        return 0; // Undefined or unknown
    }
  };

  const ShimmerComponent = () => {
    return (
      <Grid container rowGap={3} sx={{ width: { md: pxTovW(1100) } }}>
        {Array.from({ length: 12 }, (_, index) => (
          <Grid item xs={12} md={4} key={index}>
            <ShimmerManageHwCard key={index} />
          </Grid>
        ))}
      </Grid>
    );
  };
  return (
    <Box sx={styles.root}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: pxToRem(20), md: pxTovW(45), lg: pxTovW(30) },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            justifyContent: { xs: 'center', md: 'flex-start' },
            alignItems: { md: 'center', lg: 'center' },
            textAlign: { xs: 'center', lg: 'start' },
            gap: { xs: pxToRem(4), md: pxTovW(10), lg: pxTovW(10) },
          }}
        >
          <Typography variant="h1" fontWeight={700}>Browse Assessment Library</Typography>
          {totalCount && totalCount > 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#E0F1FF',
                border: '1px solid #007CDC',
                p: { xs: `${pxToRem(4)} ${pxToRem(5)}`, md: `${pxTovW(8)} ${pxTovW(17)}` },
                borderRadius: {
                  xs: pxToRem(5),
                  md: pxTovW(15),
                  lg: pxTovW(16),
                },
                marginX: { xs: 'auto', md: 0 },
                '& .MuiTypography-root > span': {
                  color: '#007CDC',
                },
              }}
            >
              <Typography
                variant={isMobile ? 'h4' : 'h2'}
                fontWeight={600}
                fontFamily={'poppins'}
              >
                <span>{roundingAssessmentNumber(totalCount)}</span> ready-made assessments available
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: {
              xs: 'space-between',
              md: 'space-between',
              lg: 'flex-start',
            },
            gap: { xs: pxToRem(10), md: pxTovW(15), lg: pxTovW(10) },
            // marginLeft: { lg: pxTovW(60) },
          }}
        >
          <Box sx={styles.selectContainer}>
            <Typography variant="h4">Choose Class & Subject</Typography>
            <SelectMenu
              placeholder="select"
              value={
                (class_subject_info?.classname ?? '') +
                (class_subject_info?.section ?? '') +
                ' ' +
                (class_subject_info?.subject ?? '')
              }
              onChange={() => ({})}
              optionList={[]}
              rootStyles={{
                width: { xs: pxToRem(152), md: pxTovW(540), lg: pxTovW(230) },
                height: { xs: pxToRem(30), md: pxTovW(75), lg: pxTovW(56) },
              }}
              disabled={true}
            />
          </Box>

          <Box sx={styles.selectContainer}>
            <Typography variant="h4">Select Chapter</Typography>
            <SelectMenu
              placeholder="select"
              value={
                selected_chapter?.chapterTitle
                  ? selected_chapter?.chapterTitle
                  : ''
              }
              clearIcon={true}
              onChange={(value: string) => {
                // Find the chapter object by matching the selected title
                if (!value) {
                  dispatch(resetSelectedChapter());
                  dispatch(resetSelectedTopic());
                } else {
                  const selectedChapterObj = chapter_topic_data?.chapters?.find(
                    (chapter) => chapter.chapterTitle === value
                  );
                  dispatch(resetSelectedTopic());
                  if (selectedChapterObj) {
                    const chapterData = {
                      chapterTitle: selectedChapterObj.chapterTitle,
                      chapterId: selectedChapterObj.chapterId,
                    };
                    dispatch(setSelectedChapter(chapterData));
                  }
                }
              }}
              optionList={
                chapter_topic_data?.chapters?.map((chapter) => {
                  return {
                    name: chapter.chapterTitle,
                    id: chapter.chapterId,
                  };
                }) || []
              }
              rootStyles={{
                width: { xs: pxToRem(152), md: pxTovW(540), lg: pxTovW(280) },
                height: { xs: pxToRem(30), md: pxTovW(75), lg: pxTovW(56) },
              }}
            />
          </Box>

          <Box sx={styles.selectContainer}>
            <Typography variant="h4">Select Topic</Typography>
            <SelectMenu
              placeholder="select"
              value={
                selected_topic?.topicTitle ? selected_topic?.topicTitle : ''
              }
              disabled={selected_chapter ? false : true}
              clearIcon={true}
              onChange={(value: string) => {
                // setSelectedTopic(value);
                if (!value) {
                  dispatch(resetSelectedTopic());
                } else {
                  const selectedTopicObj = chapter_topic_data?.chapters
                    .find(
                      (chapter) =>
                        Number(chapter.chapterId) ===
                        Number(selected_chapter?.chapterId)
                    )
                    ?.topics?.find((topic) => topic.topicTitle === value);

                  if (selectedTopicObj) {
                    const topicData = {
                      topicTitle: selectedTopicObj.topicTitle,
                      topicId: selectedTopicObj.topicId,
                    };
                    dispatch(setSelectedTopic(topicData));
                  }
                }
              }}
              optionList={
                chapter_topic_data?.chapters
                  ?.find((chapter) => {
                    return (
                      Number(chapter.chapterId) ===
                      Number(selected_chapter?.chapterId)
                    );
                  })
                  ?.topics?.map((topic) => {
                    return {
                      name: topic.topicTitle,
                      id: topic.topicId,
                    };
                  }) || []
              }
              rootStyles={{
                width: { xs: pxToRem(152), md: pxTovW(540), lg: pxTovW(280) },
                height: { xs: pxToRem(30), md: pxTovW(75), lg: pxTovW(56) },
              }}
            />
          </Box>
          <Box sx={styles.selectContainer}>
            <Typography variant="h4">Choose Assessment Type</Typography>
            <SelectMenu
              placeholder="select"
              value={selectedAssessmentType}
              onChange={(value: string) => {
                setSelectedAssessmentType(value);
              }}
              optionList={[
                {
                  name: 'Formative',
                  id: AssessmentEnum.ASSESSMENT_FORMATIVE_ASSESSMENT,
                  nonEditableItem: true,
                },
                {
                  name: 'Homework',
                  id: AssessmentEnum.ASSESSMENT_HOMEWORK,
                },
              ]}
              rootStyles={{
                width: { xs: pxToRem(152), md: pxTovW(540), lg: pxTovW(230) },
                height: { xs: pxToRem(30), md: pxTovW(75), lg: pxTovW(56) },
              }}
            />
          </Box>
          <Box
            sx={{
              width: { xs: 'max-content', md: pxTovW(540), lg: pxTovW(115) },
              display: 'flex',
              alignItems: { xs: 'flex-end', md: 'flex-end', lg: 'flex-end' },
              justifyContent: { xs: 'end', lg: 'start' },
              marginBottom: { xs: 0, md: 0, lg: pxTovW(5) },
              // marginX: { xs: 'auto', md: 0 }
            }}
          >
            <FilterSortPopup
              iconName="Sort"
              title="Sort By"
              options={[
                'Alphabetical: A to Z',
                'Alphabetical: Z to A',
                // 'Popularity',
                'Newest',
                'Oldest',
                'Duration: Longest to Shortest',
                'Duration: Shortest to Longest',
                'Difficulty: Easy to Hard',
                'Difficulty: Hard to Easy',
              ]}
              sortFunction={sortFunction}
            />
          </Box>
        </Box>
      </Box>
      {/* {totalCount && totalCount > 0 && selection && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: { xs: pxToRem(10), md: pxTovW(30) },
          }}
        >
          <Typography variant="h2">
            Based on your selection, {roundingAssessmentNumber(totalCount)}{' '}
            ready-made assessments were found
          </Typography>
        </Box>
      )} */}
      <Box
        sx={{
          paddingTop: { xs: pxToRem(20), md: pxTovW(40) },
          paddingBottom: { xs: pxToRem(20), md: pxTovW(40) },
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading === true ? (
          // <Loader />
          // <ShimmerComponent />
          <Loader />
        ) : error === true ? (
          <NoContentCard variant="error" icon="error" text="Error Occured" />
        ) : assesment_flow_data &&
          assesment_flow_data?.assessments.length <= 0 ? (
          <NoContentCard
            variant="info"
            icon="cards"
            text="No Assessments Found"
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: pxToRem(20), md: pxTovW(20) },
            }}
          >
            <Grid
              container
              // columns={{ xs: 1, md: 3 }}
              rowGap={3}
              // columnGap={'26px'}
              sx={{ width: { md: pxTovW(1800), lg: pxTovW(1430) } }}
            >
              {/* {assesment_flow_data?.assessments?.map((card, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <AssessmentCard
                    homework={card}
                    onClick={() => cardClickHandler(card)}
                    assessCardType="library"
                  />
                </Grid>
              ))} */}
              {paginatedData?.map((card, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <AssessmentCard
                    homework={card}
                    onClick={() => cardClickHandler(card)}
                    assessCardType="library"
                  />
                </Grid>
              ))}
            </Grid>
            <Pagination
              page={pageNumber}
              count={totalPages}
              color="secondary"
              onChange={async (
                event: React.ChangeEvent<unknown>,
                page: number
              ) => {
                setPageNumber(page);
              }}
              sx={styles.pagination}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AssesmentLibrary;
