import {
  Loader,
  ProfileComponent,
  deserify,
  isValidEmail,
  isValidMobileNumber,
  updateUserDetailsByUsername,
  useCommonServiceClientContext,
} from '@geneo2-web/shared-ui';
import { OTPEnum, ProfileRolesEnum } from '@protos/user_management/ums.db_pb';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';
import { useAppSelector } from '../../../reduxStore/reduxHooks';
import {
  PROFILE_OTP_SCREEN,
  SWITCH_PROFILE,
  VERIFY_EMAIL,
  VERIFY_PHONE,
} from '../../../routeHandling/RoutesNomenclature';
import { interactionEvent } from '../../Auth/auth.events';
import {
  setOtpInfo,
  setUserInfo,
  setVerifyPath,
} from '../../Auth/reducer/auth.slice';
import { setToastInfo } from '../../Home/reducer/homeDashboard.slice';

export const Profile = () => {
  const {
    user_info,
    user_validation_info,
    is_logged_in_offline,
    multiple_profiles,
    is_mcb_user,
  } = deserify(useAppSelector((state) => state.auth));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<'loading' | 'completed' | 'error'>(
    'completed'
  );
  const location = useLocation();
  const pathSegments = location.pathname.split('/');

  const { isOffline } = useDownloadContext();
  const isOfflineFrontend = isOffline || is_logged_in_offline;

  useEffect(() => {
    getTeacherProfile();
  }, []);
  const { UMSLoginAPIServiceV1ClientWithStatusCodeHandler } =
    useCommonServiceClientContext();

  const getTeacherProfile = async () => {
    try {
      const teacherProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.fetchTeacherProfile(
          {
            teacherProfileId: user_info?.teacherProfileId,
          }
        );
      if (teacherProfile.data) {
        dispatch(setUserInfo(teacherProfile.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addPhoneNumber = async (phoneNumber: string) => {
    if (!isValidMobileNumber(phoneNumber) || phoneNumber.length !== 10) {
      dispatch(
        setToastInfo({
          label: 'invalid Phone Number',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    if (isOfflineFrontend) {
      dispatch(
        setToastInfo({
          label: 'User is Offline',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    try {
      setLoading('loading');
      const updateProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateTeacherProfile(
          {
            teacherProfileId: user_info?.teacherProfileId,
            phoneNumber: phoneNumber,
            phoneVerify: 0,
          }
        );

      if (updateProfile.status === 200 && updateProfile.data) {
        setLoading('completed');
        dispatch(setUserInfo(updateProfile.data));
        await updateUserDetailsByUsername(updateProfile.data);

        dispatch(
          setToastInfo({
            label: `Phone Number ${
              user_info?.phoneNumber ? 'Changed' : 'Added'
            } Successfully`,
            variant: 'success',
            open: true,
          })
        );
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'Phone Number Addition failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: 'invalid Phone Number',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    } finally {
      setLoading('completed');
    }
  };

  const deletePhoneNumber = async () => {
    try {
      if (isOfflineFrontend) {
        dispatch(
          setToastInfo({
            label: 'User is Offline',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      setLoading('loading');
      const updateProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateTeacherProfile(
          {
            teacherProfileId: user_info?.teacherProfileId,
            phoneNumber: '',
            phoneVerify: 0,
          }
        );

      if (updateProfile.status === 200 && updateProfile.data) {
        setLoading('completed');
        dispatch(setUserInfo(updateProfile.data));
        await updateUserDetailsByUsername(updateProfile.data);
        dispatch(
          setToastInfo({
            label: 'Phone Number Deleted Successfully',
            variant: 'success',
            open: true,
          })
        );
        await interactionEvent({
          url: '',
          context: 'Phone_number',
          name: 'DELETE',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: ' Phone Number delete failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: ' Phone Number delete failed',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    } finally {
      setLoading('completed');
    }
  };

  const addEmail = async (email: string) => {
    console.log('addEmail:', email);

    if (!isValidEmail(email)) {
      dispatch(
        setToastInfo({
          label: 'invalid Email Id',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    if (isOfflineFrontend) {
      dispatch(
        setToastInfo({
          label: 'User is Offline',
          variant: 'error',
          open: true,
        })
      );
      return;
    }
    try {
      setLoading('loading');
      const updateProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateTeacherProfile(
          {
            teacherProfileId: user_info?.teacherProfileId,
            email: email,
            emailVerify: 0,
          }
        );

      if (updateProfile.status === 200 && updateProfile.data) {
        setLoading('completed');
        dispatch(setUserInfo(updateProfile.data));
        await updateUserDetailsByUsername(updateProfile.data);
        dispatch(
          setToastInfo({
            label: `Email Id ${
              user_info?.email ? 'Changed' : 'Added'
            } Successfully`,
            variant: 'success',
            open: true,
          })
        );
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: ' Email Id Addition failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: 'invalid Email Id',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    } finally {
      setLoading('completed');
    }
  };

  const deleteEmail = async () => {
    try {
      if (isOfflineFrontend) {
        dispatch(
          setToastInfo({
            label: 'User is Offline',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      setLoading('loading');
      const updateProfile =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.updateTeacherProfile(
          {
            teacherProfileId: user_info?.teacherProfileId,
            email: '',
            emailVerify: 0,
          }
        );

      if (updateProfile.status === 200) {
        setLoading('completed');
        dispatch(setUserInfo(updateProfile.data));
        await updateUserDetailsByUsername(updateProfile.data);
        dispatch(
          setToastInfo({
            label: 'Email Id Deleted Successfully',
            variant: 'success',
            open: true,
          })
        );
        await interactionEvent({
          url: '',
          context: 'email',
          name: 'DELETE',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: ' Email Id deletion failed',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      dispatch(
        setToastInfo({
          label: ' Email Id deletion failed',
          variant: 'error',
          open: true,
        })
      );
      console.log(error);
    } finally {
      setLoading('completed');
    }
  };

  const verifyPhoneHandler = async () => {
    try {
      if (isOfflineFrontend) {
        dispatch(
          setToastInfo({
            label: 'User is Offline',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      setLoading('loading');
      const sendOtp =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getPhoneOTP({
          phoneNumber: user_info?.phoneNumber,
          userName: user_info?.userName,
          otpType: OTPEnum.TEACHER_VERIFICATION,
        });

      if (sendOtp.status === 200) {
        setLoading('completed');
        // dispatch(
        //   setToastInfo({
        //     label: 'otp sent to your phone',
        //     variant: 'success',
        //     open: true,
        //   })
        // );
        dispatch(
          setOtpInfo({
            verification_code: sendOtp.verificationCode,
            otp_type: OTPEnum.TEACHER_VERIFICATION,
          })
        );

        dispatch(setVerifyPath(VERIFY_PHONE));

        navigate(PROFILE_OTP_SCREEN);
        await interactionEvent({
          url: '',
          context: 'Phone_number',
          name: 'VERIFY',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'invalid Phone Number',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error) {
      setLoading('error');
      console.log(error);
    }
  };

  const verifyEmailHandler = async () => {
    try {
      if (isOfflineFrontend) {
        dispatch(
          setToastInfo({
            label: 'User is Offline',
            variant: 'error',
            open: true,
          })
        );
        return;
      }
      setLoading('loading');
      const sendOtp =
        await UMSLoginAPIServiceV1ClientWithStatusCodeHandler.getEmailOTP({
          email: user_info?.email,
          userName: user_info?.userName,
          otpType: OTPEnum.TEACHER_VERIFICATION,
        });

      if (sendOtp.status === 200) {
        setLoading('completed');
        // dispatch(
        //   setToastInfo({
        //     label: 'otp sent to your phone',
        //     variant: 'success',
        //     open: true,
        //   })
        // );
        dispatch(
          setOtpInfo({
            verification_code: sendOtp.verificationCode,
            otp_type: OTPEnum.TEACHER_VERIFICATION,
          })
        );

        dispatch(setVerifyPath(VERIFY_EMAIL));

        navigate(PROFILE_OTP_SCREEN);
        await interactionEvent({
          url: '',
          context: 'email',
          name: 'VERIFY',
          pathSegments: pathSegments,
          isOffline: isOffline,
        });
      } else {
        setLoading('error');
        dispatch(
          setToastInfo({
            label: 'invalid Email Id',
            variant: 'error',
            open: true,
          })
        );
      }
    } catch (error: any) {
      setLoading('error');
      console.log(error);
      dispatch(
        setToastInfo({
          label: error.message
            ? `${error.message.replace(/\[.*?\]/g, '')}`
            : 'Something went wrong',
          variant: 'error',
          open: true,
        })
      );
    }
  };

  return user_info ? (
    loading === 'loading' ? (
      <Loader />
    ) : (
      <ProfileComponent
        id={user_info.teacherProfileId}
        role={ProfileRolesEnum.PROFILE_ROLE_TEACHER}
        profilePics={user_info.profilePics}
        isMCBuser={is_mcb_user}
        name={
          (user_info.firstName || '') +
          (user_info.lastName ? ' ' + user_info.lastName : '')
        }
        phone={
          user_info.phoneNumber === '' ? undefined : user_info.phoneNumber
          // mergeCountryCodeAndPhone({
          //     countryCode: user_info.phoneCountry,
          //     phoneNumber: user_info.phoneNumber,
          //   })
        }
        countryCode={user_info.phoneCountry}
        addPhoneNumber={addPhoneNumber}
        isEmailVerified={user_info.emailVerify === 1}
        isPhoneVerified={user_info.phoneVerify === 1}
        verifyPhoneHandler={verifyPhoneHandler}
        verifyEmailHandler={verifyEmailHandler}
        deletePhoneNumber={deletePhoneNumber}
        email={user_info.email}
        addEmail={addEmail}
        deleteEmailId={deleteEmail}
        schoolBoardMediumInfo={user_info.schoolDetails || []}
        teachClassSubjects={user_info.teachClassSubjects}
        showSwitchProfile={multiple_profiles && multiple_profiles?.length > 1}
        switchProfileClickHandler={() => {
          navigate(SWITCH_PROFILE);
        }}
        isOffline={isOffline}
        eventCollection={{
          addPhoneNumberEvent: async () => {
            await interactionEvent({
              url: '',
              context: 'Phone_number',
              name: 'ADD',
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
          },
          changePhoneNumberEvent: async () => {
            await interactionEvent({
              url: '',
              context: 'Phone_number',
              name: 'CHANGE',
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
          },
          addEmailEvent: async () => {
            await interactionEvent({
              url: '',
              context: 'email',
              name: 'ADD',
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
          },
          changeEmailEvent: async () => {
            await interactionEvent({
              url: '',
              context: 'email',
              name: 'CHANGE',
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
          },
        }}
      />
    )
  ) : null;
};
