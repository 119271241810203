import { Disclaimer } from '@geneo2-web/shared-ui';
import { useLocation } from 'react-router-dom';
import { interactionEvent } from '../../Auth/auth.events';
import { useDownloadContext } from '../../../app/Context/DownloadContextProviderV2';

export const TeacherDisclaimer = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const { isOffline } = useDownloadContext();

  return (
    <Disclaimer
      eventCollection={{
        tabInteraction: async (name: string | undefined) => {
          if (name)
            await interactionEvent({
              url: '',
              context: 'disclaimer_&_policies',
              name: name,
              pathSegments: pathSegments,
              isOffline: isOffline,
            });
        },
      }}
    />
  );
};
